import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { JwtPayload, jwtDecode } from 'jwt-decode'
import { Button } from 'storybook-ui'

import { FooterContainer } from 'components/FooterContainer'
import { Header } from 'components/Header'
import { Spinner } from 'components/Spinner'

import {
  EXTEND_SUBSCRIPTION,
  extendSubscriptionAction,
  setUUIDAction,
} from 'root-redux/actions/user'
import { selectActionList, selectAppName } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { getDateFromTimeStamp } from 'helpers/date'

import { PinnedNote } from 'modules/unsubscribe/components/PinnedNote'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  APP_BUTTON_THEMES,
  READABLE_APP_NAMES,
  RETENTION_APP_COLORS_MAP,
} from 'root-constants'

import { StyledExtendPlan as S } from './ExtendPlan.styles'
import { StatusModal } from './components'

interface IExtendPlanTokenPayload extends JwtPayload {
  extension_period: number
  period_end: string
  customer_id: string
}

const MILLISECONDS_IN_SECOND = 1000

export const ExtendPlan: React.FC = () => {
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()
  const appName = useSelector(selectAppName)
  const userId = useSelector(selectUUID)
  const fetchingActionsList = useSelector(selectActionList)
  const [extensionPeriod, setExtensionPeriod] = useState<number>(0)
  const [extensionDate, setExtensionDate] = useState<string | null>(null)
  const [token, setToken] = useState<string>('')

  const isExtendSubscriptionInProgress =
    fetchingActionsList.includes(EXTEND_SUBSCRIPTION)

  const updatePlanExtensionParams = useCallback(
    (extensionToken: string) => {
      const decodedPayload = jwtDecode(
        extensionToken,
      ) as IExtendPlanTokenPayload

      if (
        !decodedPayload ||
        !decodedPayload.extension_period ||
        !decodedPayload.period_end ||
        !decodedPayload.customer_id
      ) {
        goTo(PageId.LOGIN)
        return
      }

      const extensionDateTimestamp = getDateFromTimeStamp(
        new Date(decodedPayload.period_end).getTime() / MILLISECONDS_IN_SECOND,
      )

      setExtensionPeriod(decodedPayload.extension_period)
      setExtensionDate(extensionDateTimestamp)
      setToken(extensionToken)
      dispatch(setUUIDAction(decodedPayload.customer_id))

      eventLogger.logExtendSubscriptionOfferShown(
        decodedPayload.extension_period,
        decodedPayload.customer_id,
      )
    },
    [dispatch],
  )

  useEffect(() => {
    const query = new URLSearchParams(search)
    const extensionToken = query.get('extension_token')

    if (!extensionToken) {
      goTo(PageId.LOGIN)
      return
    }

    updatePlanExtensionParams(extensionToken)
  }, [dispatch, search, updatePlanExtensionParams])

  const extendSubscription = () => {
    eventLogger.logExtendSubscriptionOfferAccepted(extensionPeriod, userId)
    dispatch(extendSubscriptionAction(token, extensionPeriod))
  }

  return (
    <>
      {isExtendSubscriptionInProgress && <Spinner />}
      <Header />
      <S.Root>
        <div>
          <S.Title color={RETENTION_APP_COLORS_MAP[appName]}>
            <Trans
              i18nKey="extendPlan.title"
              values={{
                monthsQty: extensionPeriod,
                appName: READABLE_APP_NAMES[appName],
              }}
            />
          </S.Title>
          <PinnedNote>
            <Trans
              i18nKey="extendPlan.note"
              values={{
                appName: READABLE_APP_NAMES[appName],
                date: extensionDate,
              }}
            />
          </PinnedNote>
          <S.Description>
            <Trans
              i18nKey="extendPlan.description1"
              values={{
                appName: READABLE_APP_NAMES[appName],
              }}
            />
          </S.Description>
          <S.Description>
            <Trans
              i18nKey="extendPlan.description2"
              values={{
                monthsQty: extensionPeriod,
                appName: READABLE_APP_NAMES[appName],
              }}
            />
          </S.Description>
        </div>
        <S.Disclaimer>{t`extendPlan.disclaimer`}</S.Disclaimer>
      </S.Root>
      <FooterContainer>
        <Button theme={APP_BUTTON_THEMES[appName]} onClick={extendSubscription}>
          {t('extendPlan.accept')}
        </Button>
      </FooterContainer>
      <StatusModal extendPeriod={extensionPeriod} periodEnd={extensionDate} />
    </>
  )
}
