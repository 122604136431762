import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'
import {
  selectUserSecondariesSubscriptionInfo,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'

import { SubscriptionContainer as Container } from 'common-styles'
import { AppName } from 'root-constants'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import { TSubscriptionDetailsProps } from './constants'

export const CanceledSubscriptionDetails: React.FC<
  TSubscriptionDetailsProps
> = ({ extra, secondary }: TSubscriptionDetailsProps) => {
  const { t } = useTranslation()
  const subscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const secondarySubscriptionInfo = useSelector(
    selectUserSecondariesSubscriptionInfo,
  )
  const appName = useSelector(selectAppName)
  const userSubscriptionInfo =
    appName === AppName.WOOFZ && secondary
      ? secondarySubscriptionInfo[0]
      : subscriptionInfo

  const cancelationDate = extra?.canceledAt || userSubscriptionInfo?.canceledAt

  return (
    userSubscriptionInfo && (
      <>
        {cancelationDate && (
          <S.SubscriptionRow>
            <span>{t('subscription.cancelationDate')}</span>
            <Container.InfoValue>{cancelationDate}</Container.InfoValue>
          </S.SubscriptionRow>
        )}
        <S.SubscriptionRow withoutBottomOffset>
          <span>{t('subscription.accessValidUntil')}</span>
          <Container.InfoValue>
            {userSubscriptionInfo.isOnTrialPeriod
              ? userSubscriptionInfo.trialEndDate
              : userSubscriptionInfo.endDate}
          </Container.InfoValue>
        </S.SubscriptionRow>
      </>
    )
  )
}
