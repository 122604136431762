import {
  AppName,
  DancebitUserGoal,
  FitmeUserGoal,
  LuvlyUserGoal,
  NutrimateUserGoal,
  WoofzUserGoal,
} from 'root-constants'

export const PAUSE_SUBSCRIPTION_OPTION_VALUES = {
  YES: 'yes',
  NO: 'no',
}

type TAppUserGoalsMap = {
  [AppName.LUVLY]: Record<LuvlyUserGoal, string>
  [AppName.DANCEBIT]: Record<DancebitUserGoal, string>
  [AppName.NUTRIMATE]: Record<NutrimateUserGoal, string>
  [AppName.FITME]: Record<FitmeUserGoal, string>
  [AppName.WOOFZ]: Record<WoofzUserGoal, string>
}

export const USER_GOALS_MAP: TAppUserGoalsMap = {
  [AppName.LUVLY]: {
    [LuvlyUserGoal.WRINKLE_FREE_SKIN]: 'retention.userGoals.wrinklesFree',
    [LuvlyUserGoal.REDUCE_FACE_FAT]: 'retention.userGoals.reduceFat',
    [LuvlyUserGoal.BOTH_OPTIONS]: 'retention.userGoals.tightenedSkin',
    [LuvlyUserGoal.TIGHTEN_SKIN]: 'retention.userGoals.tightenedSkin',
  },
  [AppName.DANCEBIT]: {
    [DancebitUserGoal.STAY_FIT]: 'retention.userGoals.changingHabits',
    [DancebitUserGoal.LOSE_WEIGHT]: 'retention.userGoals.weightLoss',
  },
  [AppName.NUTRIMATE]: {
    [NutrimateUserGoal.LOSE_WEIGHT]: 'retention.userGoals.loseWeight',
    [NutrimateUserGoal.GET_FIT]: 'retention.userGoals.getFit',
    [NutrimateUserGoal.BUILD_HEALTHY_HABITS]:
      'retention.userGoals.changeHabits',
    [NutrimateUserGoal.GET_HEALTHY_HABITS]: 'retention.userGoals.changeHabits',
  },
  [AppName.FITME]: {
    [FitmeUserGoal.WEIGHT]: 'retention.userGoals.weight',
    [FitmeUserGoal.MUSCLES]: 'retention.userGoals.gainMuscles',
    [FitmeUserGoal.HEALTH]: 'retention.userGoals.health',
    [FitmeUserGoal.FIT]: 'retention.userGoals.fit',
  },
  [AppName.WOOFZ]: {
    [WoofzUserGoal.BEHAVIOUR_PROBLEMS]: 'retention.userGoals.behaviourProblems',
    [WoofzUserGoal.NEW_TRICKS]: 'retention.userGoals.newTricks',
    [WoofzUserGoal.HOME_ADAPTATION]: 'retention.userGoals.homeAdaptation',
    [WoofzUserGoal.PREVENT_PROBLEMS]: 'retention.userGoals.preventProblems',
  },
}
