/* eslint-disable max-lines */
import { commonApi, subscriptionsApi, userApi } from 'api'
import dayjs from 'dayjs'
import { getAuth, signOut } from 'firebase/auth'
import i18n from 'i18next'
import { AnyAction } from 'redux'

import {
  setErrorAction,
  setSubscriptionExtendStatusAction,
  setSubscriptionUpdateVariantAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  ExtendSubscriptionStatus,
  SubscriptionUpdateVariant,
} from 'root-redux/reducers/common'
import { selectAppName, selectSource } from 'root-redux/selects/common'
import {
  selectAnswers,
  selectAuthToken,
  selectPaymentMethod,
  selectUUID,
  selectUserContactEmail,
  selectUserSecondariesSubscriptionInfo,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'

import { getDateFromTimeStamp } from 'helpers/date'
import {
  getActiveUserSubscriptionFromRawUserSubscriptions,
  getUserSecondarySubscriptionsFromRawUserSubscriptions,
} from 'helpers/getActiveUserSubscriptionFromRawUserSubscriptions'
import { getAvailableFeatures } from 'helpers/getAvailableFeatures'
import { getLoginMethodFromLocalStorage } from 'helpers/getLoginMethodFromLocalStorage'
import { getUserPaymentMethodFromRaw } from 'helpers/getUserPaymentMethodFromRaw'
import { getUserPurchasedInAppsFromRawUserSubscriptions } from 'helpers/getUserPurchasedInAppsFromRawUserSubscriptions'

import { setIsModalShownAction } from 'modules/contactForm/redux'

import { TAnswer, TAnswers } from 'models/common.model'
import {
  IUserInAppPurchase,
  IUserSubscriptionsInfo,
  TCustomerAvailableFeatures,
} from 'models/commonApi.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  AppName,
  NO_DATA_ERROR,
  PaymentSystem,
  RETENTION_FLOW_APPS,
  SUBSCRIPTION_MISSING_ERROR,
  SubscriptionStatus,
  SubscriptionType,
  SubscriptionUIStatus,
} from 'root-constants'

const MODULE_NAME = 'USER'

export const SET_AUTH_TOKEN = `${MODULE_NAME}/SET_AUTH_TOKEN`
export const SET_USER_SUBSCRIPTION_INFO = `${MODULE_NAME}/SET_USER_SUBSCRIPTION_INFO`
export const SET_USER_SECONDARIES_SUBSCRIPTION_INFO = `${MODULE_NAME}/SET_USER_SECONDARIES_SUBSCRIPTION_INFO`
export const SET_USER_INAPPS = `${MODULE_NAME}/SET_USER_INAPPS`
export const SET_USER_CONTACT_EMAIL = `${MODULE_NAME}/SET_USER_CONTACT_EMAIL`
export const SET_USER_NAME = `${MODULE_NAME}/SET_USER_NAME`
export const UNSUBSCRIBE = `${MODULE_NAME}/UNSUBSCRIBE`
export const CANCEL_EXTRA = `${MODULE_NAME}/CANCEL_EXTRA`
export const UNSUBSCRIBE_SECONDARY = `${MODULE_NAME}/UNSUBSCRIBE_SECONDARY`
export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const GET_AUTOLOGIN_TOKEN = `${MODULE_NAME}/GET_AUTOLOGIN_TOKEN`
export const SET_AUTOLOGIN_TOKEN = `${MODULE_NAME}/SET_AUTOLOGIN_TOKEN`
export const SET_ANSWERS = `${MODULE_NAME}/SET_ANSWERS`
export const SET_ANSWERS_FROM_BACKEND = `${MODULE_NAME}/SET_ANSWERS_FROM_BACKEND`
export const SEND_USER_ANSWERS = `${MODULE_NAME}/SEND_USER_ANSWERS`
export const SET_AVAILABLE_FEATURES = `${MODULE_NAME}/SET_AVAILABLE_FEATURES`
export const SET_USER_COUNTRY = `${MODULE_NAME}/SET_USER_COUNTRY`
export const SET_USER_COHORT = `${MODULE_NAME}/SET_USER_COHORT`
export const PAUSE_SUBSCRIPTION = `${MODULE_NAME}/PAUSE_SUBSCRIPTION`
export const SET_PAYMENT_METHOD = `${MODULE_NAME}/SET_PAYMENT_METHOD`
export const SET_PAYMENT_SYSTEM = `${MODULE_NAME}/SET_PAYMENT_SYSTEM`
export const SET_UPGRADE_PURCHASED = `${MODULE_NAME}/SET_UPGRADE_PURCHASED`
export const EXTEND_SUBSCRIPTION = `${MODULE_NAME}/EXTEND_SUBSCRIPTION`

export function setAuthTokenAction(authToken: string): IAction<string> {
  return {
    type: SET_AUTH_TOKEN,
    payload: authToken,
  }
}

export function setUserContactEmailAction(
  customerContactEmail: string,
): IAction<string> {
  return {
    type: SET_USER_CONTACT_EMAIL,
    payload: customerContactEmail,
  }
}

export function setUserNameAction(userName: string): IAction<string> {
  return {
    type: SET_USER_NAME,
    payload: userName,
  }
}

export function setUserSubscriptionsInfoAction(
  subscriptionInfo: IUserSubscriptionsInfo | null,
): IAction<IUserSubscriptionsInfo | null> {
  return {
    type: SET_USER_SUBSCRIPTION_INFO,
    payload: subscriptionInfo,
  }
}

export function setUserSecondariesSubscriptionsInfoAction(
  subscriptionsInfo: IUserSubscriptionsInfo[],
): IAction<IUserSubscriptionsInfo[]> {
  return {
    type: SET_USER_SECONDARIES_SUBSCRIPTION_INFO,
    payload: subscriptionsInfo,
  }
}

export function setUserInAppsInfoAction(
  inapps: IUserInAppPurchase[] | null,
): IAction<IUserInAppPurchase[] | null> {
  return {
    type: SET_USER_INAPPS,
    payload: inapps,
  }
}

export function setAutologinTokenAction(payload: string): IAction<string> {
  return {
    type: SET_AUTOLOGIN_TOKEN,
    payload,
  }
}

export function setPaymentMethodAction(payload: string): IAction<string> {
  return {
    type: SET_PAYMENT_METHOD,
    payload,
  }
}

export function setPaymentSystemAction(
  payload?: PaymentSystem,
): IAction<PaymentSystem> {
  return {
    type: SET_PAYMENT_SYSTEM,
    payload,
  }
}

export function setUserUpgradePurchasedAction(payload: boolean): AnyAction {
  return {
    type: SET_UPGRADE_PURCHASED,
    payload,
  }
}

export function setAnswersAction({
  answers,
  pageId,
}: {
  answers: TAnswer
  pageId: string
}): IAction<Record<string, TAnswer>> {
  return {
    type: SET_ANSWERS,
    payload: { [pageId]: answers },
  }
}

export function setAnswersFromBackendAction(
  answers: Record<PageId, TAnswer>,
): IAction<Record<PageId, TAnswer>> {
  return {
    type: SET_ANSWERS_FROM_BACKEND,
    payload: answers,
  }
}

export function setAvailableFeatures(
  features: TCustomerAvailableFeatures,
): IAction<TCustomerAvailableFeatures> {
  return {
    type: SET_AVAILABLE_FEATURES,
    payload: features,
  }
}

export function setUserCountry(country: string): IAction<string> {
  return {
    type: SET_USER_COUNTRY,
    payload: country,
  }
}

export function setUserCohort(cohort?: string): IAction<string> {
  return {
    type: SET_USER_COHORT,
    payload: cohort,
  }
}

export function updateUserSecondarySubscriptionStatusAction(
  subscriptionId: string,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const userSecondarySubscriptions =
      selectUserSecondariesSubscriptionInfo(state)

    const updatedSecondarySubscriptions = userSecondarySubscriptions.map(
      (subscription) => {
        if (subscription.subscriptionId === subscriptionId) {
          return {
            ...subscription,
            uiStatus: SubscriptionUIStatus.CANCELED,
            status: SubscriptionStatus.CANCELLED,
          }
        }

        return subscription
      },
    )

    dispatch(
      setUserSecondariesSubscriptionsInfoAction(updatedSecondarySubscriptions),
    )
  }
}

export function getUserSubscriptionsInfoAction(errorCallback: () => void): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const appName = selectAppName(state)
    const authToken = selectAuthToken(state)
    const source = selectSource(state)
    const email = selectUserContactEmail(state)

    if (!authToken) {
      goTo(PageId.LOGIN)
      return
    }

    const response = await commonApi.getUserSubscriptionsInfo(
      authToken,
      appName,
    )

    if (
      response.success &&
      (!response.data?.subscriptions.length || !response.data?.subscriptions)
    ) {
      eventLogger.logLoginFailed({
        error: SUBSCRIPTION_MISSING_ERROR,
        method: getLoginMethodFromLocalStorage(),
        source,
        email,
      })

      dispatch(setErrorAction(i18n.t('somethingWrongError')))
      dispatch(setIsModalShownAction(true))
      errorCallback()
      return
    }

    if (response.success && response.data && response.data.subscriptions) {
      const subscriptionInfo =
        getActiveUserSubscriptionFromRawUserSubscriptions(
          response.data.subscriptions,
        )

      const secondariesSubscriptionInfo =
        getUserSecondarySubscriptionsFromRawUserSubscriptions(
          response.data.secondaries,
        )

      dispatch(setUserSubscriptionsInfoAction(subscriptionInfo))
      dispatch(
        setUserSecondariesSubscriptionsInfoAction(secondariesSubscriptionInfo),
      )

      const inApps = getUserPurchasedInAppsFromRawUserSubscriptions(
        response.data.inapps,
      )

      dispatch(setUserInAppsInfoAction(inApps))

      return
    }

    if (response.success && !response.data) {
      dispatch(setErrorAction(i18n.t('login.error.userNotFound')))
      goTo(PageId.LOGIN)
      errorCallback()

      eventLogger.logLoginFailed({
        error: i18n.t('login.error.userNotFound'),
        source,
        email,
        method: getLoginMethodFromLocalStorage(),
      })
      return
    }

    if (!response.success && response.status === 400) {
      dispatch(setErrorAction(''))
      goTo(PageId.LOGIN)
      errorCallback()
      return
    }

    if (!response.success && response.status === 404) {
      dispatch(setErrorAction(i18n.t('login.error.customerNotFound')))
      goTo(PageId.LOGIN)
      errorCallback()
      return
    }

    dispatch(
      setErrorAction(response.data?.error || i18n.t('login.error.common')),
    )
    goTo(PageId.LOGIN)
    errorCallback()
  }
}

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function getCustomerInfoAction({
  hasEvent,
}: {
  hasEvent: boolean
}): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const appName = selectAppName(state)
    const authToken = selectAuthToken(state)
    const source = selectSource(state)

    const response = await commonApi.getCustomerInfo(authToken, appName)

    if (response.success && response.data) {
      const { customer } = response.data
      dispatch(
        setUserContactEmailAction(
          customer.contact_email || customer.login_email,
        ),
      )
      dispatch(setUserNameAction(customer.onboarding?.name || ''))
      dispatch(setUUIDAction(customer.public_id))
      dispatch(setAnswersFromBackendAction(customer.onboarding))
      dispatch(setUserCountry(customer.country))
      dispatch(setUserCohort(customer.cohort_name))

      const availableFeatures = getAvailableFeatures(
        customer.available_features,
      )
      dispatch(setAvailableFeatures(availableFeatures))

      hasEvent &&
        eventLogger.logLoginCompleted({
          userId: customer.public_id,
          source,
          email: customer.contact_email,
          method: getLoginMethodFromLocalStorage(),
        })
    }
  }
}

export const sendUserAnswersAction =
  (isFinished = false): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const appName = selectAppName(state)
    const answers = selectAnswers(state) as TAnswers
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_ANSWERS))

    const response = await userApi.saveUserAnswers({
      appName,
      uuid,
      answers,
      isFinished,
    })

    if (!response.success) {
      dispatch(
        setErrorAction(i18n.t('retention.cancelingPlan.somethingWentWrong')),
      )
    }

    dispatch(stopFetching(SEND_USER_ANSWERS))
  }

export function unsubscribeUserByTokenAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const authToken = selectAuthToken(state)
    const appName = selectAppName(state) as AppName
    const userSubscriptionInfo = selectUserSubscriptionInfo(state)
    const source = selectSource(state)
    const email = selectUserContactEmail(state)
    const userId = selectUUID(state)

    const loginMethod = getLoginMethodFromLocalStorage()

    let paymentMethod: string

    if (!authToken || !userSubscriptionInfo) {
      goTo(PageId.LOGIN)
      return
    }

    dispatch(startFetching(UNSUBSCRIBE))

    try {
      const userStatusResponse = await userApi.getUserStatus(userId, appName)
      paymentMethod = getUserPaymentMethodFromRaw(userStatusResponse.data.state)
    } catch {
      paymentMethod = NO_DATA_ERROR
    }

    try {
      const response = await subscriptionsApi.unsubscribeByToken({
        subscriptionId: userSubscriptionInfo.subscriptionId,
        token: authToken,
        appName,
      })

      if (response.success) {
        eventLogger.logSubscriptionCancelled({
          userId,
          productId: userSubscriptionInfo.subscriptionId,
          price: `${userSubscriptionInfo.price}${userSubscriptionInfo.currency}`,
          paymentMethod,
          source,
          email,
          method: loginMethod,
          subscriptionType: SubscriptionType.SUBSCRIPTION,
        })

        dispatch(
          setUserSubscriptionsInfoAction({
            ...userSubscriptionInfo,
            status: SubscriptionStatus.CANCELLED,
          }),
        )
        dispatch(stopFetching(UNSUBSCRIBE))
        return
      }

      dispatch(setIsModalShownAction(true))
      dispatch(setErrorAction(i18n.t('commonError')))

      if (RETENTION_FLOW_APPS.includes(appName)) {
        dispatch(
          setSubscriptionUpdateVariantAction(
            SubscriptionUpdateVariant.UNSUBSCRIBE,
          ),
        )
      }

      eventLogger.logCancelSubscriptionFailed({
        userId,
        error: response.data.error || i18n.t('login.error.common'),
        source,
        email,
        method: loginMethod,
      })

      dispatch(getUserSubscriptionsInfoAction(() => signOut(getAuth())))

      dispatch(stopFetching(UNSUBSCRIBE))
    } catch (error: any) {
      eventLogger.logCancelSubscriptionFailed({
        userId,
        error,
        source,
        email,
        method: loginMethod,
      })
      dispatch(getUserSubscriptionsInfoAction(() => signOut(getAuth())))
      dispatch(stopFetching(UNSUBSCRIBE))
    }
  }
}

export function unsubscribeSecondarySubscriptionAction(
  secondarySubscription: IUserSubscriptionsInfo,
): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const authToken = selectAuthToken(state)
    const appName = selectAppName(state) as AppName
    const userSubscriptionInfo = selectUserSubscriptionInfo(state)
    const source = selectSource(state)
    const email = selectUserContactEmail(state)
    const userId = selectUUID(state)
    const paymentMethod = selectPaymentMethod(state)
    const loginMethod = getLoginMethodFromLocalStorage()

    if (!authToken || !userSubscriptionInfo) {
      goTo(PageId.LOGIN)
      return
    }

    dispatch(startFetching(UNSUBSCRIBE_SECONDARY))

    try {
      const response = await subscriptionsApi.unsubscribeByToken({
        subscriptionId: secondarySubscription.subscriptionId,
        token: authToken,
        appName,
      })

      if (response.success) {
        eventLogger.logSubscriptionCancelled({
          userId,
          productId: secondarySubscription.subscriptionId,
          price: `${secondarySubscription.price}${userSubscriptionInfo.currency}`,
          paymentMethod,
          source,
          email,
          method: loginMethod,
          subscriptionType: SubscriptionType.SECONDARY,
        })

        dispatch(
          updateUserSecondarySubscriptionStatusAction(
            secondarySubscription.subscriptionId,
          ),
        )

        dispatch(stopFetching(UNSUBSCRIBE_SECONDARY))

        return
      }

      dispatch(setIsModalShownAction(true))
      dispatch(setErrorAction(i18n.t('commonError')))

      if (RETENTION_FLOW_APPS.includes(appName)) {
        dispatch(
          setSubscriptionUpdateVariantAction(
            SubscriptionUpdateVariant.UNSUBSCRIBE,
          ),
        )
      }

      eventLogger.logCancelSubscriptionFailed({
        userId,
        error: response.data.error || i18n.t('login.error.common'),
        source,
        email,
        method: loginMethod,
      })

      dispatch(getUserSubscriptionsInfoAction(() => signOut(getAuth())))

      dispatch(stopFetching(UNSUBSCRIBE_SECONDARY))
    } catch (error: any) {
      eventLogger.logCancelSubscriptionFailed({
        userId,
        error,
        source,
        email,
        method: loginMethod,
      })
      dispatch(getUserSubscriptionsInfoAction(() => signOut(getAuth())))
      dispatch(stopFetching(UNSUBSCRIBE_SECONDARY))
    }
  }
}

export function getAutologinTokenAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<string>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const appName = selectAppName(state)

    dispatch(startFetching(GET_AUTOLOGIN_TOKEN))

    const autologinTokenResponse = await userApi.getAutologinToken(
      uuid,
      appName,
    )

    if (!autologinTokenResponse.success || !autologinTokenResponse.data) {
      dispatch(setErrorAction(i18n.t('commonError')))
      dispatch(stopFetching(GET_AUTOLOGIN_TOKEN))
    }

    dispatch(setAutologinTokenAction(autologinTokenResponse.data.custom_token))

    dispatch(stopFetching(GET_AUTOLOGIN_TOKEN))
  }
}

export function getPaymentDataAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<string>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const appName = selectAppName(state) as AppName
    const userId = selectUUID(state)

    try {
      const userStatusResponse = await userApi.getUserStatus(userId, appName)

      if (userStatusResponse.success && userStatusResponse.data) {
        const paymentMethod = getUserPaymentMethodFromRaw(
          userStatusResponse.data.state,
        )
        dispatch(setPaymentMethodAction(paymentMethod))
        dispatch(
          setPaymentSystemAction(
            userStatusResponse.data.state.config.payment_system,
          ),
        )
        dispatch(
          setUserUpgradePurchasedAction(
            userStatusResponse.data.state.upgrade.is_upgrade_passed,
          ),
        )
      }
    } catch {
      dispatch(setPaymentMethodAction(NO_DATA_ERROR))
    }
  }
}

export function pauseSubscriptionAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const authToken = selectAuthToken(state)
    const appName = selectAppName(state) as AppName
    const userSubscriptionInfo = selectUserSubscriptionInfo(state)
    const source = selectSource(state)
    const email = selectUserContactEmail(state)
    const userId = selectUUID(state)
    const paymentMethod = selectPaymentMethod(state)

    if (!authToken || !userSubscriptionInfo) {
      goTo(PageId.LOGIN)
      return
    }

    dispatch(startFetching(PAUSE_SUBSCRIPTION))

    try {
      const response = await subscriptionsApi.pauseSubscription({
        subscriptionId: userSubscriptionInfo.subscriptionId,
        token: authToken,
        appName,
      })

      const todayTimestamp = dayjs().unix()

      if (response.success) {
        eventLogger.logSubscriptionPause({
          userId,
          productId: userSubscriptionInfo.subscriptionId,
          paymentMethod,
          source,
          email,
          pauseDate: getDateFromTimeStamp(todayTimestamp),
        })

        dispatch(
          setUserSubscriptionsInfoAction({
            ...userSubscriptionInfo,
            status: SubscriptionStatus.NOT_ACTIVE,
          }),
        )
        dispatch(stopFetching(PAUSE_SUBSCRIPTION))
        return
      }

      eventLogger.logSubscriptionPauseFailed({
        userId,
        productId: userSubscriptionInfo.subscriptionId,
        paymentMethod,
        source,
        email,
        pauseDate: getDateFromTimeStamp(todayTimestamp),
        error: response.data?.error || i18n.t('somethingWrongError'),
      })

      dispatch(
        setSubscriptionUpdateVariantAction(SubscriptionUpdateVariant.PAUSE),
      )

      dispatch(stopFetching(PAUSE_SUBSCRIPTION))
    } catch (error: any) {
      const todayTimestamp = dayjs().unix()

      eventLogger.logSubscriptionPauseFailed({
        userId,
        productId: userSubscriptionInfo.subscriptionId,
        paymentMethod,
        source,
        email,
        pauseDate: getDateFromTimeStamp(todayTimestamp),
        error: error.toString(),
      })

      dispatch(stopFetching(PAUSE_SUBSCRIPTION))
    }
  }
}

export function cancelExtraAction(productId: string): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const authToken = selectAuthToken(state)
    const appName = selectAppName(state) as AppName
    const userId = selectUUID(state)
    const userSubscriptionInfo = selectUserSubscriptionInfo(state)
    const source = selectSource(state)
    const email = selectUserContactEmail(state)
    const paymentMethod = selectPaymentMethod(state)
    const loginMethod = getLoginMethodFromLocalStorage()

    if (!authToken) {
      goTo(PageId.LOGIN)
      return
    }

    dispatch(startFetching(CANCEL_EXTRA))

    try {
      const response = await subscriptionsApi.cancelExtra({
        productId,
        token: authToken,
        appName,
      })

      if (response.success) {
        userSubscriptionInfo &&
          eventLogger.logSubscriptionCancelled({
            userId,
            productId: userSubscriptionInfo.subscriptionId,
            price: `${userSubscriptionInfo.price}${userSubscriptionInfo?.currency}`,
            paymentMethod,
            source,
            email,
            method: loginMethod,
            subscriptionType: SubscriptionType.UPSELL,
          })

        dispatch(stopFetching(CANCEL_EXTRA))
        return
      }

      eventLogger.logCancelSubscriptionFailed({
        userId,
        error: response.data.error || i18n.t('somethingWrongError'),
        source,
        email,
        method: loginMethod,
        subscriptionType: SubscriptionType.UPSELL,
      })

      dispatch(setIsModalShownAction(true))
      dispatch(setErrorAction(i18n.t('commonError')))

      dispatch(getUserSubscriptionsInfoAction(() => signOut(getAuth())))

      dispatch(stopFetching(CANCEL_EXTRA))
    } catch (error: any) {
      eventLogger.logCancelSubscriptionFailed({
        userId,
        error: error.toString(),
        source,
        email,
        method: loginMethod,
        subscriptionType: SubscriptionType.UPSELL,
      })

      dispatch(getUserSubscriptionsInfoAction(() => signOut(getAuth())))
      dispatch(stopFetching(CANCEL_EXTRA))
    }
  }
}

export function extendSubscriptionAction(
  extensionToken: string,
  period: number,
): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const userId = selectUUID(state)

    dispatch(startFetching(EXTEND_SUBSCRIPTION))

    try {
      const response = await subscriptionsApi.extendSubscription(extensionToken)

      if (response.success) {
        eventLogger.logExtendSubscriptionOfferSuccess(period, userId)
        dispatch(
          setSubscriptionExtendStatusAction(ExtendSubscriptionStatus.SUCCESS),
        )
        dispatch(stopFetching(EXTEND_SUBSCRIPTION))
        return
      }

      eventLogger.logExtendSubscriptionOfferFailed({
        extensionPeriod: period,
        userId,
        error: response.data?.error || i18n.t('somethingWrongError'),
      })

      dispatch(
        setSubscriptionExtendStatusAction(ExtendSubscriptionStatus.ERROR),
      )

      dispatch(stopFetching(EXTEND_SUBSCRIPTION))
    } catch (error: any) {
      eventLogger.logExtendSubscriptionOfferFailed({
        extensionPeriod: period,
        userId,
        error: error.toString(),
      })
      dispatch(
        setSubscriptionExtendStatusAction(ExtendSubscriptionStatus.ERROR),
      )

      dispatch(stopFetching(EXTEND_SUBSCRIPTION))
    }
  }
}
