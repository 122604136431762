import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'
import { selectUserSubscriptionInfo } from 'root-redux/selects/user'

import { RetentionFlowContainer } from 'modules/unsubscribe/components/RetentionFlowContainer'

import { SubscriptionContainer as Container } from 'common-styles'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import { STATUSES_TEXT_MAP } from './constants'

export const GiftedSubscription: React.FC = () => {
  const { t } = useTranslation()
  const appName = useSelector(selectAppName)
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)

  if (!userSubscriptionInfo) {
    return null
  }

  return (
    <RetentionFlowContainer>
      <S.DetailedSubscriptionsWrapper>
        <Container.Root>
          <Container.CardTitle>
            {t('subscription.yourPlan', { context: appName })}
          </Container.CardTitle>
          <S.SubscriptionRow>
            <span>
              <Trans i18nKey="subscription.status" />
            </span>
            <S.Status status={userSubscriptionInfo.uiStatus}>
              <Trans
                i18nKey={STATUSES_TEXT_MAP[userSubscriptionInfo.uiStatus]}
              />
            </S.Status>
          </S.SubscriptionRow>
          <S.SubscriptionRow>
            <span>{t('subscription.expirationDate')}</span>
            <Container.InfoValue>
              {userSubscriptionInfo.nextPayment}
            </Container.InfoValue>
          </S.SubscriptionRow>
        </Container.Root>
      </S.DetailedSubscriptionsWrapper>
    </RetentionFlowContainer>
  )
}
