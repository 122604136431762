import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import { AccountProvider } from 'components/AccountProvider'

import { ContactForm } from 'modules/contactForm'
import {
  CanceledPlan,
  ContinuedPlan,
  DiscountOffer,
  ExtendPlan,
  OtherIssues,
  PauseSubscription,
  ReachYourGoal,
  Reasons,
  SubscriptionDetails,
  TechnicalIssues,
  ThanksForFeedback,
} from 'modules/unsubscribe/pages'
import { UnsubscribeLogin } from 'modules/unsubscribeLogin'
import { UnsubscribeResetPassword } from 'modules/unsubscribeResetPassword'

import { PageId } from 'page-constants'

export const RouteList: React.FC = () =>
  useRoutes([
    { index: true, element: <Navigate to="login" /> },
    {
      path: PageId.LOGIN,
      element: <UnsubscribeLogin />,
    },
    {
      path: PageId.RESET_PASSWORD,
      element: <UnsubscribeResetPassword />,
    },
    {
      element: <AccountProvider />,
      children: [
        {
          path: PageId.SUBSCRIPTION_DETAILS,
          element: <SubscriptionDetails />,
        },
        {
          path: PageId.REACH_YOUR_GOAL,
          element: <ReachYourGoal />,
        },
        {
          path: PageId.REASONS,
          element: <Reasons />,
        },
        {
          path: PageId.THANKS_FOR_FEEDBACK,
          element: <ThanksForFeedback />,
        },
        {
          path: PageId.PAUSE_SUBSCRIPTION,
          element: <PauseSubscription />,
        },
        {
          path: PageId.DISCOUNT_OFFER,
          element: <DiscountOffer />,
        },
        {
          path: PageId.CONTINUED_SUBSCRIPTION,
          element: <ContinuedPlan />,
        },
        {
          path: PageId.CANCELED_SUBSCRIPTION,
          element: <CanceledPlan />,
        },
        {
          path: PageId.TECHNICAL_ISSUES,
          element: <TechnicalIssues />,
        },
        {
          path: PageId.OTHER_ISSUES,
          element: <OtherIssues />,
        },
      ],
    },
    { path: PageId.CONTACT_FORM, element: <ContactForm /> },
    {
      path: PageId.EXTEND_SUBSCRIPTION,
      element: <ExtendPlan />,
    },
    { path: '*', element: <Navigate to={PageId.LOGIN} /> },
  ])
