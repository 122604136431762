import { IRequestOptions, TResponseResult } from 'models/api.model'

import { ApiService } from 'services/api.service'

import { AppName, RequestMethod } from 'root-constants'

export class SubscriptionsApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  pauseSubscription({
    subscriptionId,
    token,
    appName,
  }: {
    subscriptionId: string
    token: string
    appName: AppName
  }): Promise<TResponseResult<{ status: boolean }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: {
        token,
        app_name: appName,
      },
    }

    return this.api.makeRequest<{ status: boolean }>(
      `v1/subscriptions/${subscriptionId}/pause`,
      options,
    )
  }

  unsubscribeByToken({
    subscriptionId,
    token,
    appName,
  }: {
    subscriptionId: string
    token: string
    appName: string
  }): Promise<TResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.DELETE,
      params: {
        token,
        app_name: appName,
      },
    }

    return this.api.makeRequest<never>(
      `v1/subscriptions/${subscriptionId}`,
      options,
    )
  }

  cancelExtra({
    productId,
    token,
    appName,
  }: {
    productId: string
    token: string
    appName: AppName
  }): Promise<TResponseResult<{ status: boolean }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        token,
        product_id: productId,
        app_name: appName,
      },
    }

    return this.api.makeRequest<{ status: boolean }>(
      'v1/extras/cancel',
      options,
    )
  }

  extendSubscription(
    extensionToken: string,
  ): Promise<TResponseResult<{ status: boolean }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: {
        extension_token: extensionToken,
      },
    }

    return this.api.makeRequest<{ status: boolean }>(
      `v1/subscriptions/extend`,
      options,
    )
  }
}
