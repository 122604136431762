import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'
import { selectUserSubscriptionInfo } from 'root-redux/selects/user'

import { SubscriptionContainer as Container } from 'common-styles'
import { SubscriptionUIStatus } from 'root-constants'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import { STATUSES_TEXT_MAP } from './constants'

export const LifetimeSubscription: React.FC = () => {
  const { t } = useTranslation()
  const appName = useSelector(selectAppName)
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)

  if (!userSubscriptionInfo) {
    return null
  }

  return (
    <S.DetailedSubscriptionsWrapper>
      <Container.Root>
        <Container.CardTitle>
          {t('subscription.yourPlan', { context: appName })}
        </Container.CardTitle>
        <S.SubscriptionRow>
          <span>
            <Trans i18nKey="subscription.status" />
          </span>
          <S.Status status={SubscriptionUIStatus.ACTIVE}>
            <Trans i18nKey={STATUSES_TEXT_MAP[SubscriptionUIStatus.ACTIVE]} />
          </S.Status>
        </S.SubscriptionRow>
        <S.SubscriptionRow>
          <span>{t('subscription.startDate')}</span>
          <Container.InfoValue>
            {userSubscriptionInfo.trialStartDate ||
              userSubscriptionInfo.startDate}
          </Container.InfoValue>
        </S.SubscriptionRow>
        <S.SubscriptionRow>
          <span>{t('subscription.subscriptionPrice')}</span>
          <Container.InfoValue>
            {t('subscription.priceAmount', {
              currency: userSubscriptionInfo.currencySymbol,
              amount:
                userSubscriptionInfo.subAmount || userSubscriptionInfo.price,
            })}
          </Container.InfoValue>
        </S.SubscriptionRow>
      </Container.Root>
    </S.DetailedSubscriptionsWrapper>
  )
}
