import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectAmplitudeApiKey, selectAppName } from 'root-redux/selects/common'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'

import {
  EventLoggerInstanceName,
  NOVE8_APPS,
  USER_ID_KEY,
} from 'root-constants'

export const useAmplitudeInitialization = (isUnsubscribe?: boolean): void => {
  const amplitudeUserId = sessionStorage.getItem(USER_ID_KEY)
  const amplitudeApiKey = useSelector(selectAmplitudeApiKey)
  const appName = useSelector(selectAppName)

  useLayoutEffect(() => {
    if (eventLogger.isAmplitudeActive) return

    if (isUnsubscribe && !NOVE8_APPS.includes(appName)) return

    if (amplitudeUserId && amplitudeApiKey) {
      const amplitudeService = new Amplitude({
        apiKey: amplitudeApiKey,
        instanceName: EventLoggerInstanceName.AMPLITUDE,
        userId: amplitudeUserId,
      })

      amplitudeService.configure()
      eventLogger.init(amplitudeService)
    }
  }, [amplitudeApiKey, amplitudeUserId])
}
