import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'
import { selectUserCountry } from 'root-redux/selects/user'

import { AppName, VAT_INCLUDED_COUNTRY } from 'root-constants'

export const useVatInfo = (): boolean => {
  const appName = useSelector(selectAppName)
  const userCountry = useSelector(selectUserCountry)

  return useMemo(() => {
    if (appName === AppName.FITME) return false

    return VAT_INCLUDED_COUNTRY[appName].includes(userCountry)
  }, [appName, userCountry])
}
