import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { GrowthBook } from '@growthbook/growthbook-react'

import {
  selectUUID,
  selectUserSecondariesSubscriptionInfo,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { eventLogger } from 'services/eventLogger.service'

import { CurrentEnvironment } from 'root-constants'

export const growthBook = new GrowthBook({
  enableDevMode: true,
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
})

const enum GrowthbookTestName {}

// hardcoded on frontend side instead of getting experiments_map from backend
const DEV_GROWTHBOOK_EXPERIMENTS_MAP: Record<
  GrowthbookTestName,
  Record<number, string>
> = {}

const PROD_GROWTHBOOK_EXPERIMENTS_MAP: Record<
  GrowthbookTestName,
  Record<number, string>
> = {}

export const useGrowthBook = (): void => {
  const uuid = useSelector(selectUUID)
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const userSecondarySubscriptions = useSelector(
    selectUserSecondariesSubscriptionInfo,
  )
  const location = useLocation()

  const experimentsMap =
    getCurrentEnv() === CurrentEnvironment.PROD
      ? PROD_GROWTHBOOK_EXPERIMENTS_MAP
      : DEV_GROWTHBOOK_EXPERIMENTS_MAP

  useEffect(() => {
    if (!userSubscriptionInfo || userSecondarySubscriptions.length) return

    growthBook.init({ streaming: true })
  }, [userSecondarySubscriptions, userSubscriptionInfo])

  useEffect(() => {
    growthBook.setAttributes({
      id: uuid,
    })
  }, [uuid])

  useEffect(() => {
    growthBook.setURL(window.location.href)
  }, [location])

  useEffect(() => {
    growthBook.subscribe((experiment, result) => {
      const experimentKey = experiment.key || ''

      if (!experimentsMap[experimentKey] || !result.hashUsed) {
        return
      }

      const variantId = String(result.variationId)

      eventLogger.logGrowthBookAbSegmentName({
        variantId: `ab_variant_${variantId}`,
        experimentKey,
        variantName: experimentsMap[experimentKey][variantId],
      })
    })
  }, [experimentsMap])
}
