import { eventLogger } from 'services/eventLogger.service'

import { EVENT_SOURCE, ZENDESK_CHAT_PUBLIC_KEYS } from 'root-constants'

export const setZendeskChat = ({
  appName,
  setIsZendeskLoaded,
}: {
  appName: string
  setIsZendeskLoaded: (value) => void
}): void => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = 'ze-snippet'
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_CHAT_PUBLIC_KEYS[appName]}`
  script.onload = () => {
    window.zE('messenger', 'show')
    window.zE('messenger', 'open')

    window.zE('messenger:on', 'open', () => {
      const source = window.location.pathname.includes('reset-password')
        ? EVENT_SOURCE.RESET_PASSWORD
        : EVENT_SOURCE.ACCOUNT_PROFILE

      eventLogger.logSupportVidgetClicked(source)
    })

    setIsZendeskLoaded && setIsZendeskLoaded(true)
  }

  document.head.appendChild(script)

  window.zESettings = {
    webWidget: {
      position: { horizontal: 'right', vertical: 'top' },
    },
  }
}
