import { useSelector } from 'react-redux'

import { selectAppName } from 'root-redux/selects/common'

import { AppName, RETENTION_FLOW_APPS } from 'root-constants'

export const useRetentionFlowApp = () => {
  const appName = useSelector(selectAppName) as AppName

  return RETENTION_FLOW_APPS.includes(appName)
}
