/* eslint-disable camelcase */
import dayjs from 'dayjs'

import { getDateFromTimeStamp } from 'helpers/date'
import { roundToNearestTenth } from 'helpers/roundToNearestTenth'

import {
  IRawUserExtraSubscription,
  IRawUserSubscriptionsInfo,
  IUserExtraSubscription,
  IUserSubscriptionsInfo,
} from 'models/commonApi.model'

import {
  CENTS_IN_DOLLAR,
  CURRENCY_SYMBOLS,
  FIXED_TERM_TRIAL_CYCLES,
} from 'root-constants'

const getExtraSubscriptionsFromRawUserSubscriptions = (
  extras: IRawUserExtraSubscription[],
): IUserExtraSubscription[] => {
  return extras.map(
    ({ canceled_at, created_at, product_id, amount, trial, ...rest }) => {
      const trialDaysCount = trial?.days_count || 0

      const trialBillingCycleDays = FIXED_TERM_TRIAL_CYCLES.includes(
        trialDaysCount,
      )
        ? trialDaysCount
        : roundToNearestTenth(trialDaysCount)

      return {
        ...rest,
        createdAt: getDateFromTimeStamp(created_at),
        productId: product_id,
        amount: amount / CENTS_IN_DOLLAR,
        ...(canceled_at && { canceledAt: getDateFromTimeStamp(canceled_at) }),
        ...(trial && {
          trialPrice: trial.amount / CENTS_IN_DOLLAR,
          trialStartDate: getDateFromTimeStamp(trial.start),
          trialEndDate: getDateFromTimeStamp(trial.end),
          trialDaysCount: trialBillingCycleDays,
        }),
      }
    },
  )
}

const convertRawUserSubscription = ({
  id: subscriptionId,
  product: { product_id: productId },
  current_period_start: startDate,
  current_period_end: endDate,
  status,
  ui_status: uiStatus,
  trial: {
    amount: trialAmount,
    start: trialStartDate,
    end: trialEndDate,
    days_count: trialDaysCount,
    sub_amount: trialSubAmount,
  },
  recurring: {
    interval_count: intervalCount,
    amount,
    currency,
    interval,
    sub_amount: subAmount,
  },
  next_payment: nextPayment,
  paused_at: pausedAt,
  canceled_at: canceledAt,
  extras,
  metadata,
}: IRawUserSubscriptionsInfo): IUserSubscriptionsInfo => {
  const trialBillingCycleDays = FIXED_TERM_TRIAL_CYCLES.includes(trialDaysCount)
    ? trialDaysCount
    : roundToNearestTenth(trialDaysCount)

  const todayTimestamp = dayjs().unix()

  return {
    subscriptionId,
    trialStartDate: getDateFromTimeStamp(trialStartDate),
    trialEndDate: getDateFromTimeStamp(trialEndDate),
    isOnTrialPeriod: dayjs(trialEndDate).isAfter(todayTimestamp),
    startDate: getDateFromTimeStamp(startDate),
    endDate: getDateFromTimeStamp(endDate),
    trialDaysCount: trialBillingCycleDays,
    intervalCount,
    interval,
    status,
    uiStatus,
    price: amount / CENTS_IN_DOLLAR,
    trialPrice: trialAmount / CENTS_IN_DOLLAR,
    currency: currency.toUpperCase(),
    currencySymbol: CURRENCY_SYMBOLS[currency.toLowerCase()] || '',
    nextPayment: getDateFromTimeStamp(nextPayment),
    isGift: metadata?.is_gift,
    ...(productId && { productId }),
    ...(extras && {
      extras: getExtraSubscriptionsFromRawUserSubscriptions(extras),
    }),
    ...(pausedAt && { pausedAt: getDateFromTimeStamp(pausedAt) }),
    ...(canceledAt && { canceledAt: getDateFromTimeStamp(canceledAt) }),
    ...(trialSubAmount && { trialSubAmount: trialSubAmount / CENTS_IN_DOLLAR }),
    ...(subAmount && { subAmount: subAmount / CENTS_IN_DOLLAR }),
  }
}

export const getUserSecondarySubscriptionsFromRawUserSubscriptions = (
  rawUserSubscriptionsInfo: IRawUserSubscriptionsInfo[] = [],
): IUserSubscriptionsInfo[] => {
  return rawUserSubscriptionsInfo.map((subscription) =>
    convertRawUserSubscription(subscription),
  )
}

export const getActiveUserSubscriptionFromRawUserSubscriptions = (
  rawUserSubscriptionsInfo: IRawUserSubscriptionsInfo[] = [],
): IUserSubscriptionsInfo => {
  const [rawActiveSubscription] = rawUserSubscriptionsInfo.sort(
    (a, b) => +b.id - +a.id,
  )

  return convertRawUserSubscription(rawActiveSubscription)
}
