import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { store } from 'root-redux/store'

import { App } from './App'
import './i18n'
import './styles/rootStyles.css'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    MSStream: any
    // ZenDesk plugin
    zE: any
    zESettings: any
    webkit: {
      messageHandlers?: {
        jsHandler?: {
          postMessage: (value: string) => void
        }
      }
    }
    JSBridge: {
      postMessage: (value: string) => void
    }
  }
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
