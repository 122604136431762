import { IUserExtraSubscription } from 'models/commonApi.model'

import {
  ExtraSubscription,
  ExtraSubscriptionUIStatus,
  HiddenInAppProduct,
  SecondarySubscription,
  SubscriptionStatus,
  SubscriptionUIStatus,
} from 'root-constants'

import { ActiveSubscriptionDetails } from './ActiveSubscriptionDetails'
import { CanceledSubscriptionDetails } from './CanceledSubscriptionDetails'
import { PausedSubscriptionDetails } from './PausedSubscriptionDetails'
import { TrialSubscriptionDetails } from './TrialSubscriptionDetails'

export type TSubscriptionDetailsProps = {
  extra?: IUserExtraSubscription
  secondary?: boolean
}

export const CANCELLED_STATUSES = [
  SubscriptionStatus.CANCELLED,
  SubscriptionStatus.NOT_ACTIVE,
  SubscriptionStatus.DEACTIVATED,
]

export const STATUSES_TEXT_MAP = {
  [SubscriptionUIStatus.ACTIVE]: 'subscription.statuses.active',
  [SubscriptionUIStatus.CANCELED]: 'subscription.statuses.canceled',
  [SubscriptionUIStatus.TRIAL]: 'subscription.statuses.trial',
  [SubscriptionUIStatus.PAUSED]: 'subscription.statuses.paused',
  [ExtraSubscriptionUIStatus.NOT_ACTIVE]: 'subscription.statuses.canceled',
}

export const SMALLER_BUTTON_FONT_SIZE_LOCALES = ['fr', 'de', 'it']

export const SUBSCRIPTION_DETAILS_MAP = {
  [SubscriptionUIStatus.ACTIVE]: ActiveSubscriptionDetails,
  [SubscriptionUIStatus.CANCELED]: CanceledSubscriptionDetails,
  [SubscriptionUIStatus.TRIAL]: TrialSubscriptionDetails,
  [SubscriptionUIStatus.PAUSED]: PausedSubscriptionDetails,
}

export const EXTRA_SUBSCRIPTION_DETAILS_MAP = {
  ...SUBSCRIPTION_DETAILS_MAP,
  [ExtraSubscriptionUIStatus.NOT_ACTIVE]: CanceledSubscriptionDetails,
}

export const EXTRA_PRODUCT_NAMES_MAP: Record<ExtraSubscription, string> = {
  [ExtraSubscription.WEIGHT_LOSS_BUNDLE]:
    'subscription.extras.weightLossBundle',
  [ExtraSubscription.WEIGHT_LOSS_BUNDLE_FREE]:
    'subscription.extras.weightLossBundle',
  [ExtraSubscription.EXTRA_COURSES]: 'subscription.extras.weightLossBundle',
  [ExtraSubscription.SEVEN_DAY]: 'subscription.extras.weightLossBundle',
  [ExtraSubscription.FLAT_TUMMY]: 'subscription.extras.flatTummy',
  [ExtraSubscription.ULTIMATE_TONING_PILATES]:
    'subscription.extras.ultimateToningPilates',
  [ExtraSubscription.SUPERCHARGE_YOURSELF]:
    'subscription.extras.superchargeYourself',
  [ExtraSubscription.FEMININITY_PROGRAM]:
    'subscription.extras.femininityProgram',
  [ExtraSubscription.LUVLY_PROGRAM]: 'subscription.extras.luvlyProgram',
  [ExtraSubscription.INTENSE_CALORIE_BURNING_DANCES]:
    'subscription.extras.intenseCalorieBurningDances',
  [ExtraSubscription.CHIN_FACE_LIFTING]:
    'subscription.extras.doubleChinAndFaceLifting',
  [ExtraSubscription.CHIN_SHAPER]: 'subscription.extras.doubleChin',
  [ExtraSubscription.FACE_LIFTING]: 'subscription.extras.faceLifting',
  [ExtraSubscription.HAIR_GUIDE]: 'subscription.extras.hairCare',
  [ExtraSubscription.GIFT_CARD]: 'subscription.extras.giftCard',
  [ExtraSubscription.FITNESS_SELFCARE]: 'subscription.extras.fitnessSelfCare',
  [ExtraSubscription.FITNESS]: 'subscription.extras.dreamBody',
  [ExtraSubscription.SELFCARE]: 'subscription.extras.selfCareGuide',
  [ExtraSubscription.SIMPLE_CHANGES]: 'subscription.extras.simpleChanges',
  [ExtraSubscription.SUGAR_FREE_DESSERTS]:
    'subscription.extras.sugarFreeDesserts',
  [ExtraSubscription.WEIGHT_LOSS_GUIDES]:
    'subscription.extras.weightLossGuides',
  [ExtraSubscription.DIET]: 'subscription.extras.diet',
  [ExtraSubscription.COOKBOOK]: 'subscription.extras.cookbook',
  [ExtraSubscription.COOKBOOK_PLUS_DIET]:
    'subscription.extras.cookbookPlusDiet',
  [ExtraSubscription.BUBBLE_BUTT]: 'subscription.extras.bubbleButt',
  [ExtraSubscription.BURN_BELLY_FAT]: 'subscription.extras.burnBellyFat',
  [ExtraSubscription.SENIOR_FITNESS]: 'subscription.extras.seniorFitness',
  [ExtraSubscription.FITNESS_AND_BELLY]:
    'subscription.extras.seniorFitnessAndBelly',
  [ExtraSubscription.BUTT_AND_BELLY]: 'subscription.extras.buttAndBelly',
}

export const SECONDARY_SUBSCRIPTION_NAMES_MAP: Record<
  SecondarySubscription,
  string
> = {
  [SecondarySubscription.LUVLY_COACHING]:
    'subscription.secondaries.luvlyCoaching',
}

export const FREE_EXTRA_PROGRAMS = [
  ExtraSubscription.WEIGHT_LOSS_BUNDLE_FREE, // dancebit
  ExtraSubscription.SEVEN_DAY, // dancebit
]

// agreed with Marketing team to hide this purchases
export const HIDDEN_INAPP_PRODUCTS: string[] = [
  HiddenInAppProduct.GUASHA_AND_ROLLER,
  HiddenInAppProduct.GUASHA,
  HiddenInAppProduct.ROLLER,
]
