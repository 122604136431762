import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectUserSubscriptionInfo } from 'root-redux/selects/user'

import { IUserInAppPurchase } from 'models/commonApi.model'

import { SubscriptionContainer as Container } from 'common-styles'
import {
  CURRENCY_SYMBOLS,
  ExtraSubscription,
  HiddenInAppProduct,
  SubscriptionUIStatus,
} from 'root-constants'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import { EXTRA_PRODUCT_NAMES_MAP, HIDDEN_INAPP_PRODUCTS } from './constants'

type TInAppPurchaseProps = {
  inApp: IUserInAppPurchase
}

export const InAppPurchase: React.FC<TInAppPurchaseProps> = ({
  inApp,
}: TInAppPurchaseProps) => {
  const { t } = useTranslation()
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)

  if (
    !userSubscriptionInfo ||
    HIDDEN_INAPP_PRODUCTS.includes(inApp.productName)
  ) {
    return null
  }

  const currency = CURRENCY_SYMBOLS[inApp.currency.toLowerCase()]

  const getInAppTitle = () => {
    const getTitle = (product: ExtraSubscription | HiddenInAppProduct) =>
      `${t('subscription.extraProgram.title')} “${t(
        EXTRA_PRODUCT_NAMES_MAP[product],
      )}”`

    // In this case, the productName has format `giftCard#<card-id>`
    if (inApp.productName.includes(ExtraSubscription.GIFT_CARD)) {
      return getTitle(ExtraSubscription.GIFT_CARD)
    }

    if (EXTRA_PRODUCT_NAMES_MAP[inApp.productName]) getTitle(inApp.productName)

    return t('subscription.extraProgram.title')
  }

  return (
    <Container.Root>
      <Container.CardTitle>{getInAppTitle()}</Container.CardTitle>

      <S.SubscriptionRow>
        <span>{t('subscription.extraProgram.status')}</span>
        <S.Status status={SubscriptionUIStatus.ACTIVE}>
          {t('subscription.statuses.active')}
        </S.Status>
      </S.SubscriptionRow>

      <S.SubscriptionRow>
        <span>{t('subscription.billingPeriod')}</span>
        <Container.InfoValue isAlignedRight>
          <Trans
            i18nKey="subscription.extraProgram.oneTimePurchase"
            components={[<br />]}
          />
        </Container.InfoValue>
      </S.SubscriptionRow>
      <S.SubscriptionRow>
        <span>{t('subscription.extraProgram.startDate')}</span>
        <Container.InfoValue>{inApp.createdAt}</Container.InfoValue>
      </S.SubscriptionRow>
      <S.SubscriptionRow>
        <span>{t('subscription.extraProgram.price')}</span>
        <Container.InfoValue>
          <Trans
            i18nKey="subscription.priceAmount"
            values={{
              currency,
              amount: inApp.amount,
            }}
          />
        </Container.InfoValue>
      </S.SubscriptionRow>
    </Container.Root>
  )
}
