import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'storybook-ui'

import { resetSubscriptionExtendStatusAction } from 'root-redux/actions/common'
import { ExtendSubscriptionStatus } from 'root-redux/reducers/common'
import {
  selectAppName,
  selectSubscriptionExtendStatus,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { getPageIdFromPathname } from 'helpers/getPageIdFormPathname'

import { eventLogger } from 'services/eventLogger.service'

import errorIcon from 'assets/images/error.png'
import successIcon from 'assets/images/success.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  APP_BUTTON_THEMES,
  CONTACT_FORM_LINK,
  Color,
  READABLE_APP_NAMES,
  RETENTION_APP_COLORS_MAP,
} from 'root-constants'

import { StyledStatusModal as S } from './StatusModal.styles'

type TStatusModalProps = {
  extendPeriod: number
  periodEnd: string | null
}

export const StatusModal: React.FC<TStatusModalProps> = ({
  extendPeriod,
  periodEnd,
}: TStatusModalProps) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch: TAppDispatch = useDispatch()

  const appName = useSelector(selectAppName)
  const subscriptionExtendStatus = useSelector(selectSubscriptionExtendStatus)

  const goToSupportPage = () => {
    const source = getPageIdFromPathname(pathname)
    eventLogger.logContactSupportClicked(source)
    dispatch(resetSubscriptionExtendStatusAction())

    window.open(CONTACT_FORM_LINK, '_blank', 'noopener,noreferrer')
  }

  const onSuccessClose = async () => {
    dispatch(resetSubscriptionExtendStatusAction())

    goTo(PageId.LOGIN)
  }

  const renderContent = () => {
    if (subscriptionExtendStatus === ExtendSubscriptionStatus.SUCCESS) {
      return (
        <>
          <S.StatusImage src={successIcon} alt="success-icon" />
          <S.Title>{t('extendPlan.modal.success.title')}</S.Title>
          <S.Text>
            <Trans
              i18nKey="extendPlan.modal.success.text"
              values={{
                appName: READABLE_APP_NAMES[appName],
                monthsQty: extendPeriod,
                date: periodEnd,
              }}
            />
          </S.Text>
          <S.Text>
            <Trans
              i18nKey="extendPlan.modal.success.note"
              values={{
                appName: READABLE_APP_NAMES[appName],
                monthsQty: extendPeriod,
                date: periodEnd,
              }}
              components={{
                link: (
                  <S.ProfileButton
                    color={RETENTION_APP_COLORS_MAP[appName] || Color.BLACK}
                    onClick={() => goTo(PageId.LOGIN)}
                  >
                    <Trans
                      i18nKey="extendPlan.modal.success.profile"
                      values={{
                        appName: READABLE_APP_NAMES[appName],
                      }}
                    />
                  </S.ProfileButton>
                ),
              }}
            />
          </S.Text>
          <Button theme={APP_BUTTON_THEMES[appName]} onClick={onSuccessClose}>
            {t('extendPlan.modal.success.great')}
          </Button>
        </>
      )
    }

    return (
      <>
        <S.StatusImage src={errorIcon} alt="error-icon" />
        <S.Title>{t('extendPlan.modal.error.title')}</S.Title>
        <S.Text>{t('extendPlan.modal.error.text')}</S.Text>
        <Button theme={APP_BUTTON_THEMES[appName]} onClick={goToSupportPage}>
          {t('login.contactSupport')}
        </Button>
      </>
    )
  }

  return (
    <S.Wrapper isShown={Boolean(subscriptionExtendStatus)}>
      <S.Content>{renderContent()}</S.Content>
    </S.Wrapper>
  )
}
