import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Answer, Button } from 'storybook-ui'

import { Container } from 'components/Container'
import { FooterContainer } from 'components/FooterContainer'
import { OptionType } from 'components/Option'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  getSubscriptionListAction,
  purchaseUpgradeAction,
} from 'root-redux/actions/common'
import { unsubscribeUserByTokenAction } from 'root-redux/actions/user'
import { selectAppName, selectSubscription } from 'root-redux/selects/common'
import {
  selectAnswers,
  selectUUID,
  selectUserCountry,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useCompatibleRetentionFlowPaymentMethod } from 'hooks/useCompatibleRetentionFlowPaymentMethod'
import { useVatInfo } from 'hooks/useVatInfo'

import { RetentionStep } from 'modules/unsubscribe/components/RetentionPageContainer/constants'

import { TAnswers } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  APP_BUTTON_THEMES,
  APP_OPTION_THEMES,
  CURRENCY_SYMBOLS,
  EVENT_SOURCE,
  SubscriptionTagsType,
} from 'root-constants'

import { REASONS_OPTION_VALUES } from '../Reasons/constants'
import { StyledDiscountOffer as S } from './DiscountOffer.styles'
import { DEFAULT_DISCOUNT_PERCENTAGE, OPTION_VALUES } from './constants'

export const DiscountOffer: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const appName = useSelector(selectAppName)
  const subscription = useSelector(selectSubscription)
  const userAnswers = useSelector(selectAnswers) as TAnswers
  const userCountry = useSelector(selectUserCountry)
  const userId = useSelector(selectUUID)

  const isCompatibleRetentionFlowPaymentMethod =
    useCompatibleRetentionFlowPaymentMethod()

  const [answer, setAnswer] = useState('')

  const hasVatInfo = useVatInfo()

  useLayoutEffect(() => {
    const pricesTags = hasVatInfo
      ? `${SubscriptionTagsType.TAX},${SubscriptionTagsType.UNSUBSCRIBE}`
      : `${SubscriptionTagsType.NO_TAX},${SubscriptionTagsType.UNSUBSCRIBE}`

    dispatch(getSubscriptionListAction(pricesTags))
  }, [dispatch, hasVatInfo])

  const discountPercentage =
    subscription?.mainPrices.discountPercentage || DEFAULT_DISCOUNT_PERCENTAGE

  useEffect(() => {
    if (subscription) {
      eventLogger.logDiscountOfferScreenShow(
        `${discountPercentage}% discount on 6-months plan`,
        userId,
      )
    }
  }, [discountPercentage, subscription, userId])

  const optionProps = {
    name: PageId.DISCOUNT_OFFER,
    type: OptionType.RADIO,
    theme: APP_OPTION_THEMES[appName],
    onChange: (value: string) => {
      setAnswer(value)
    },
  }

  const handleDeclineAnswer = () => {
    const [, immediateDiscountOfferReason] = REASONS_OPTION_VALUES[appName]

    if (
      userAnswers[PageId.REASONS] === immediateDiscountOfferReason &&
      isCompatibleRetentionFlowPaymentMethod
    ) {
      goTo({
        pathname: PageId.PAUSE_SUBSCRIPTION,
        search,
      })
      return
    }

    dispatch(unsubscribeUserByTokenAction())
    goTo({
      pathname: PageId.CANCELED_SUBSCRIPTION,
      search,
    })
  }

  const acceptDiscountOffer = async () => {
    dispatch(purchaseUpgradeAction())
    goTo(
      {
        pathname: PageId.CONTINUED_SUBSCRIPTION,
        search,
      },
      { isUpgrade: true },
    )
  }

  const handleAnswer = () => {
    eventLogger.logDiscountOfferScreenClose(answer, userId)

    if (answer === OPTION_VALUES.NO) {
      handleDeclineAnswer()
      return
    }

    acceptDiscountOffer()
  }

  const getVatLabel = () => {
    switch (userCountry) {
      case 'US':
        return t('retention.discountOffer.inclTaxes')
      case 'CA':
      case 'NZ':
      case 'AU':
        return t('retention.discountOffer.inclGst')
      default:
        return t('retention.discountOffer.inclVat')
    }
  }

  if (!subscription) {
    return null
  }

  return (
    <S.Wrapper step={RetentionStep.THIRD}>
      <Container>
        <S.Description>
          <Trans
            i18nKey="retention.discountOffer.description"
            context={appName}
            values={{
              percentage: discountPercentage,
            }}
          />
        </S.Description>
        <S.SubscriptionDetails>
          <S.SubscriptionRow>
            <S.SubscriptionDetail>
              {t('retention.discountOffer.newOffer')}
            </S.SubscriptionDetail>
            <S.SubscriptionValue>
              {t('subscription.priceAmount', {
                currency: CURRENCY_SYMBOLS[subscription.currency],
                amount: subscription.mainPrices.oldPrices.fullPrice,
              })}
            </S.SubscriptionValue>
          </S.SubscriptionRow>
          <S.SubscriptionRow>
            <S.SubscriptionDetail>
              {t('retention.discountOffer.discount', {
                percentage: discountPercentage,
              })}
            </S.SubscriptionDetail>
            <S.DiscountAmount>
              -{' '}
              {t('subscription.priceAmount', {
                currency: CURRENCY_SYMBOLS[subscription.currency],
                amount: subscription.mainPrices.discountAmount,
              })}
            </S.DiscountAmount>
          </S.SubscriptionRow>
        </S.SubscriptionDetails>
        <S.NewPrice>
          <span>{t`retention.discountOffer.newPrice`}</span>
          <span>
            {t('subscription.priceAmount', {
              currency: CURRENCY_SYMBOLS[subscription.currency],
              amount: subscription.mainPrices.fullPrice,
            })}
          </span>
        </S.NewPrice>
        <S.VatLabel>{getVatLabel()}</S.VatLabel>
        <S.Question>
          {t('retention.discountOffer.title', { context: appName })}
        </S.Question>
        <S.OptionsContainer>
          <Answer {...optionProps} value={OPTION_VALUES.YES}>
            <Trans
              i18nKey="retention.discountOffer.yesOffer"
              values={{
                percentage: discountPercentage,
              }}
            />
          </Answer>
          <Answer {...optionProps} value={OPTION_VALUES.NO}>
            {t`retention.discountOffer.noOffer`}
          </Answer>
        </S.OptionsContainer>
        <S.Disclaimer>
          {t`retention.discountOffer.disclaimer`}{' '}
          <TermsOfUseLink
            source={EVENT_SOURCE.DISCOUNT_OFFER}
            text={t`retention.discountOffer.subscriptionTerms`}
          />
        </S.Disclaimer>
      </Container>
      <FooterContainer>
        <Button
          onClick={handleAnswer}
          disabled={!answer}
          theme={APP_BUTTON_THEMES[appName]}
        >
          {t`button.continue`}
        </Button>
        <S.FooterNote>{t`retention.discountOffer.discountStart`}</S.FooterNote>
      </FooterContainer>
    </S.Wrapper>
  )
}
