import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Container } from 'components/Container'
import { RadiantSpinner } from 'components/RadiantSpinner'

import { unsubscribeUserByTokenAction } from 'root-redux/actions/user'
import { selectAppName } from 'root-redux/selects/common'
import {
  selectAnswers,
  selectCustomerAvailableFeatures,
  selectIsUpgradePurchased,
  selectUUID,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useCompatibleRetentionFlowPaymentMethod } from 'hooks/useCompatibleRetentionFlowPaymentMethod'

import { getSubscriptionUIStatus } from 'helpers/getSubscriptionUIStatus'

import { PinnedNote } from 'modules/unsubscribe/components/PinnedNote'
import { RetentionPageContainer } from 'modules/unsubscribe/components/RetentionPageContainer/RetentionPageContainer'
import { RetentionStep } from 'modules/unsubscribe/components/RetentionPageContainer/constants'

import { TAnswers } from 'models/common.model'

import { Events, eventLogger } from 'services/eventLogger.service'

import { replaceHistory } from 'browser-history'
import { RetentionPageTitle as Title } from 'common-styles'
import { PageId } from 'page-constants'
import { AppName, UnsubscribeReason } from 'root-constants'

import { REASONS_OPTION_VALUES } from '../Reasons/constants'

export const ThanksForFeedback: React.FC = () => {
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()
  const appName = useSelector(selectAppName) as AppName
  const userAnswers = useSelector(selectAnswers) as TAnswers
  const isUpgradePurchased = useSelector(selectIsUpgradePurchased)
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const userId = useSelector(selectUUID)
  const { pauseAvailable } = useSelector(selectCustomerAvailableFeatures)

  const isCompatibleRetentionFlowPaymentMethod =
    useCompatibleRetentionFlowPaymentMethod()
  const { isPausedSubscription } = getSubscriptionUIStatus(
    userSubscriptionInfo?.uiStatus,
  )

  const unsubscribe = useCallback(() => {
    dispatch(unsubscribeUserByTokenAction())
    replaceHistory({
      pathname: PageId.CANCELED_SUBSCRIPTION,
      search,
    })
  }, [dispatch, search])

  const goToNextPage = useCallback(() => {
    const eventName =
      userAnswers[PageId.REASONS] === UnsubscribeReason.TECH_ISSUES ||
      userAnswers[PageId.REASONS] === UnsubscribeReason.OTHER
        ? Events.UNSUB_FLOW_QUESTION_4_END
        : Events.UNSUB_FLOW_QUESTION_3_END

    eventLogger.logQuestion({
      event: eventName,
      question: t('retention.thanksForFeedback.title'),
      answers: '',
      userId,
    })

    if (
      isPausedSubscription ||
      (appName === AppName.WOOFZ && !pauseAvailable)
    ) {
      unsubscribe()
      return
    }

    if (isUpgradePurchased) {
      replaceHistory({
        pathname: PageId.PAUSE_SUBSCRIPTION,
        search,
      })
      return
    }

    const [, immediateDiscountOfferReason] = REASONS_OPTION_VALUES[appName]

    const nextPageId =
      (userAnswers[PageId.REASONS] === immediateDiscountOfferReason ||
        !isCompatibleRetentionFlowPaymentMethod) &&
      appName !== AppName.WOOFZ
        ? PageId.DISCOUNT_OFFER
        : PageId.PAUSE_SUBSCRIPTION

    replaceHistory({
      pathname: nextPageId,
      search,
    })
  }, [
    userAnswers,
    t,
    userId,
    isPausedSubscription,
    isUpgradePurchased,
    appName,
    isCompatibleRetentionFlowPaymentMethod,
    search,
    unsubscribe,
    pauseAvailable,
  ])

  useEffect(() => {
    setTimeout(() => {
      goToNextPage()
    }, 2000)
  }, [goToNextPage])

  return (
    <RetentionPageContainer step={RetentionStep.THIRD}>
      <Container>
        <Title bottomMargin={48}>{t`retention.thanksForFeedback.title`}</Title>
        <PinnedNote>
          <Trans i18nKey="retention.thanksForFeedback.note" context={appName} />
        </PinnedNote>
      </Container>
      <RadiantSpinner isFixedPosition={false} />
    </RetentionPageContainer>
  )
}
