import { AppName, TRetentionFlowApps } from 'root-constants'

export const enum TechnicalIssuesOptionValue {
  INCOMPATIBLE_DEVICE = 'incompatible_device',
  CANT_CAST_TO_TV = 'cant_cast_to_tv',
  LOGIN_ISSUE = 'login_issue',
  NOT_AVAILABLE_IN_REGION = 'not_available_in_region',
  PROGRESS_NOT_SAVED = 'progress_not_saved',
  COULDNT_USE_PREMIUM_CONTENT = 'couldnt_use_premium_content',
  DONT_UNDERSTAND_LANGUAGE = 'dont_understand_language',
  OTHER = 'other',
}

export const OPTION_VALUES: Record<
  TRetentionFlowApps,
  TechnicalIssuesOptionValue[]
> = {
  [AppName.LUVLY]: [
    TechnicalIssuesOptionValue.INCOMPATIBLE_DEVICE,
    TechnicalIssuesOptionValue.PROGRESS_NOT_SAVED,
    TechnicalIssuesOptionValue.COULDNT_USE_PREMIUM_CONTENT,
    TechnicalIssuesOptionValue.DONT_UNDERSTAND_LANGUAGE,
    TechnicalIssuesOptionValue.OTHER,
  ],
  [AppName.DANCEBIT]: [
    TechnicalIssuesOptionValue.INCOMPATIBLE_DEVICE,
    TechnicalIssuesOptionValue.CANT_CAST_TO_TV,
    TechnicalIssuesOptionValue.LOGIN_ISSUE,
    TechnicalIssuesOptionValue.NOT_AVAILABLE_IN_REGION,
    TechnicalIssuesOptionValue.OTHER,
  ],
  [AppName.NUTRIMATE]: [
    TechnicalIssuesOptionValue.INCOMPATIBLE_DEVICE,
    TechnicalIssuesOptionValue.PROGRESS_NOT_SAVED,
    TechnicalIssuesOptionValue.COULDNT_USE_PREMIUM_CONTENT,
    TechnicalIssuesOptionValue.DONT_UNDERSTAND_LANGUAGE,
    TechnicalIssuesOptionValue.OTHER,
  ],
  [AppName.FITME]: [
    TechnicalIssuesOptionValue.INCOMPATIBLE_DEVICE,
    TechnicalIssuesOptionValue.PROGRESS_NOT_SAVED,
    TechnicalIssuesOptionValue.COULDNT_USE_PREMIUM_CONTENT,
    TechnicalIssuesOptionValue.DONT_UNDERSTAND_LANGUAGE,
    TechnicalIssuesOptionValue.OTHER,
  ],
  [AppName.WOOFZ]: [
    TechnicalIssuesOptionValue.INCOMPATIBLE_DEVICE,
    TechnicalIssuesOptionValue.PROGRESS_NOT_SAVED,
    TechnicalIssuesOptionValue.COULDNT_USE_PREMIUM_CONTENT,
    TechnicalIssuesOptionValue.DONT_UNDERSTAND_LANGUAGE,
    TechnicalIssuesOptionValue.OTHER,
  ],
}
