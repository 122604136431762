import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getAuth, signOut } from 'firebase/auth'

import { FooterContainer } from 'components/FooterContainer'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import { setErrorAction } from 'root-redux/actions/common'
import {
  UNSUBSCRIBE,
  getUserSubscriptionsInfoAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectAppName,
  selectSource,
} from 'root-redux/selects/common'
import {
  selectUUID,
  selectUserContactEmail,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useRetentionFlowApp } from 'hooks/useRetentionFlowApp'

import { getLoginMethodFromLocalStorage } from 'helpers/getLoginMethodFromLocalStorage'

import { setIsModalShownAction } from 'modules/contactForm/redux'
import { Account } from 'modules/unsubscribe/components/Account'
import { ContactSupport } from 'modules/unsubscribe/components/ContactSupport'
import { DetailsTab } from 'modules/unsubscribe/components/DetailsTabs'
import { TABS } from 'modules/unsubscribe/components/DetailsTabs/DetailsTab'
import { RetentionPageContainer } from 'modules/unsubscribe/components/RetentionPageContainer'
import {
  DetailedSubscriptions,
  GiftedSubscription,
  LifetimeSubscription,
  Subscriptions,
} from 'modules/unsubscribe/components/Subscriptions'

import { eventLogger } from 'services/eventLogger.service'

import { SubscriptionTitle } from 'common-styles'
import { AppName } from 'root-constants'

import { StyledSubscriptionDetails as S } from './SubscriptionDetails.styles'

const MAX_AVAILABLE_MONTHS_SUBSCRIPTION_PLAN = 36

export const SubscriptionDetails: React.FC = () => {
  const { t } = useTranslation()

  const dispatch: TAppDispatch = useDispatch()

  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const fetchingActionsList = useSelector(selectActionList)
  const source = useSelector(selectSource)
  const email = useSelector(selectUserContactEmail)
  const userId = useSelector(selectUUID)
  const appName = useSelector(selectAppName)
  const isRetentionFlowApp = useRetentionFlowApp()

  const [selectedTab, setSelectedTab] = useState(TABS.ACCOUNT)

  const isUnsubscribeInProgress = useMemo(
    () => fetchingActionsList.includes(UNSUBSCRIBE),
    [fetchingActionsList],
  )

  const signOutFirebase = useCallback(() => signOut(getAuth()), [])

  useEffect(() => {
    if (!userSubscriptionInfo || !email) return

    eventLogger.logSubscriptionDetailsShow({
      userId,
      source,
      email,
      method: getLoginMethodFromLocalStorage(),
    })
  }, [email, source, userId, userSubscriptionInfo])

  const handleCloseModal = useCallback(() => {
    dispatch(setErrorAction(''))

    dispatch(getUserSubscriptionsInfoAction(signOutFirebase))

    dispatch(setIsModalShownAction(false))
  }, [dispatch, signOutFirebase])

  const renderSubscriptionsTab = () => {
    if (userSubscriptionInfo?.isGift) return <GiftedSubscription />
    if (
      appName === AppName.LUVLY &&
      userSubscriptionInfo?.intervalCount ===
        MAX_AVAILABLE_MONTHS_SUBSCRIPTION_PLAN
    ) {
      return <LifetimeSubscription />
    }
    if (isRetentionFlowApp) return <DetailedSubscriptions />
    return <Subscriptions />
  }

  return !email || isUnsubscribeInProgress ? (
    <Spinner />
  ) : (
    <RetentionPageContainer>
      <SubscriptionTitle>{t('subscription.title')}</SubscriptionTitle>
      <DetailsTab activeTab={selectedTab} handleChange={setSelectedTab} />
      <S.Container>
        {selectedTab === TABS.ACCOUNT && <Account />}
        {selectedTab === TABS.SUBSCRIPTIONS && renderSubscriptionsTab()}
        <Modal onClose={handleCloseModal} hasContactSupport />
      </S.Container>
      <FooterContainer>
        <ContactSupport showHelpCenterLink={false} />
      </FooterContainer>
    </RetentionPageContainer>
  )
}
