import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledExtendPlan = {
  Root: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 167px);
    height: calc(100dvh - 167px);
  `,
  Title: styled.p<{ color: string }>`
    color: ${Color.BLUE_DARK};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 48px;
    text-align: center;

    & strong {
      color: ${({ color }) => color};
    }
  `,
  Description: styled.p`
    color: ${Color.BLACK};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 16px;
  `,
  Disclaimer: styled.p`
    color: ${Color.GRAY};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    padding: 16px;
  `,
}
