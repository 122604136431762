import React from 'react'
import { useSelector } from 'react-redux'

import { selectUserInApps } from 'root-redux/selects/user'

import { InAppPurchase } from './InAppPurchase'

export const InAppPurchases: React.FC = () => {
  const userInApps = useSelector(selectUserInApps)

  return (
    <>
      {userInApps.map((inApp) => (
        <InAppPurchase key={inApp.id} inApp={inApp} />
      ))}
    </>
  )
}
