/* eslint-disable max-lines */
import { TAnswer } from 'models/common.model'
import { IEvent, IEventLogger } from 'models/events.model'

import {
  EVENT_SOURCE,
  EventLoggerInstanceName,
  LoginMethod,
  SubscriptionType,
} from 'root-constants'

export const enum Events {
  LOGIN_PAGE_SHOW = 'account_login_screen_show',
  LOGIN_COMPLETED = 'account_login_completed',
  LOGIN_FAILED = 'account_login_failed',
  OPEN_APP = 'account_page_app_open_tap',
  SUBSCRIPTION_DETAILS_SHOW = 'subscription_details_screen_show',
  SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
  CANCEL_SUBSCRIPTION_FAILED = 'cancel_subscription_failed',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  CONTACT_SUPPORT = 'contact_support_tap',
  INSTRUCTIONS_POPUP_OPEN = 'get_instructions_popup_show',
  INSTRUCTIONS_POPUP_CLOSE = 'get_instructions_popup_close',
  HELP_CENTER = 'help_centre_tap',
  SUPPORT_VIDGET_TAP = 'support_vidget_tap',
  RESET_LINK = 'account_login_reset_link_send_tap',
  RESET_PAGE_CLOSED = 'reset_link_complete_screen_close',
  FORGOT_PASSWORD = 'account_login_forgot_password_tap',
  UNSUB_FLOW_QUESTION_1_END = 'unsub_flow_question_1_end',
  UNSUB_FLOW_QUESTION_2_END = 'unsub_flow_question_2_end',
  UNSUB_FLOW_QUESTION_3_END = 'unsub_flow_question_3_end',
  UNSUB_FLOW_QUESTION_4_END = 'unsub_flow_question_4_end',
  PAUSE_SUBSCRIPTION_SCREEN_SHOW = 'pause_subscription_offer_screen_show',
  PAUSE_SUBSCRIPTION_SCREEN_CLOSE = 'pause_subscription_offer_screen_close',
  DISCOUNT_OFFER_SCREEN_SHOW = 'discount_offer_screen_show',
  DISCOUNT_OFFER_SCREEN_CLOSE = 'discount_offer_screen_close',
  PAUSE_SUBSCRIPTION_BUTTON_TAP = 'pause_subscription_button_tap',
  SUBSCRIPTION_PAUSED = 'subscription_paused',
  SUBSCRIPTION_PAUSE_FAILED = 'subscription_pause_failed',
  SUBSCRIPTION_UPDATED = 'subscription_updated',
  SUBSCRIPTION_UPDATE_FAILED = 'subscription_update_failed',
  AB_SEGMENT = 'ab_segment',
  TURN_OFF_AUTO_RENEWAL_BUTTON_TAP = 'turn_off_auto_renewal_button_tap',
  EXTENSION_SUBSCRIPTION_OFFER_SHOW = 'extension_subscription_offer_show',
  EXTENSION_SUBSCRIPTION_OFFER_ACCEPTED = 'extension_subscription_offer_accepted',
  EXTENSION_SUBSCRIPTION_OFFER_SUCCESS = 'extension_subscription_offer_success',
  EXTENSION_SUBSCRIPTION_OFFER_FAILED = 'extension_subscription_offer_failed',
}

const PAUSE_SUBSCRIPTION_SCREEN_QUESTION = 'pause subscription'

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []
  private eventsCache: Events[] = []

  get isAmplitudeActive() {
    return this.loggers?.has(EventLoggerInstanceName.AMPLITUDE)
  }

  get getIsGiaActive() {
    return this.loggers?.has(EventLoggerInstanceName.GIA)
  }

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )

    if (!this.loggers) {
      this.loggers = new Map(entriesArr)
      this.notifyInitFinished()
      return
    }

    if (this.loggers) {
      loggers.map((logger) =>
        this.loggers?.set(
          logger.name as EventLoggerInstanceName,
          logger as IEventLogger,
        ),
      )
    }

    this.notifyInitFinished()
  }

  logLoginPageShow(source: string): void {
    if (this.eventsCache.includes(Events.LOGIN_PAGE_SHOW)) {
      return
    }

    this.eventsCache.push(Events.LOGIN_PAGE_SHOW)
    const eventProperty = { source }
    this.logEventOrPushToQueue(Events.LOGIN_PAGE_SHOW, eventProperty)
  }

  logLoginCompleted({
    source,
    email,
    userId,
    method,
  }: {
    source: string
    email: string
    userId: string
    method: string
  }): void {
    const eventProperty = {
      user_ID: userId,
      source,
      email,
      method,
    }
    this.logEventOrPushToQueue(Events.LOGIN_COMPLETED, eventProperty)
  }

  logLoginFailed({
    error,
    source,
    email,
    method,
  }: {
    error: any
    source: string
    email: string
    method: string
  }): void {
    const eventProperty = { error, method, source, email }
    this.logEventOrPushToQueue(Events.LOGIN_FAILED, eventProperty)
  }

  logSubscriptionDetailsShow({
    source,
    email,
    userId,
    method,
  }: {
    source: string
    email: string
    userId: string
    method: string
  }): void {
    const eventProperty = {
      user_ID: userId,
      source,
      email,
      method,
    }
    this.logEventOrPushToQueue(Events.SUBSCRIPTION_DETAILS_SHOW, eventProperty)
  }

  logCancelSubscriptionFailed({
    userId,
    error,
    source,
    email,
    method,
    subscriptionType = SubscriptionType.SUBSCRIPTION,
  }: {
    userId: string
    error: string
    source: string
    email: string
    method: string
    subscriptionType?: SubscriptionType
  }): void {
    const eventProperty = {
      user_ID: userId,
      error,
      source,
      email,
      method,
      subscription_type: subscriptionType,
    }
    this.logEventOrPushToQueue(Events.CANCEL_SUBSCRIPTION_FAILED, eventProperty)
  }

  logSubscriptionCancelled({
    userId,
    productId,
    price,
    paymentMethod,
    source,
    email,
    method,
    subscriptionType,
  }: {
    userId: string
    productId: string
    price: string
    paymentMethod: string
    source: string
    email: string
    method: string
    subscriptionType: SubscriptionType
  }): void {
    const eventProperty = {
      user_ID: userId,
      product_id: productId,
      price,
      payment_method: paymentMethod,
      source,
      email,
      method,
      subscription_type: subscriptionType,
    }
    this.logEventOrPushToQueue(Events.SUBSCRIPTION_CANCELLED, eventProperty)
  }

  logPauseSubscriptionScreenShow(userId: string): void {
    const eventProperty = {
      question: PAUSE_SUBSCRIPTION_SCREEN_QUESTION,
      user_ID: userId,
    }
    this.logEventOrPushToQueue(
      Events.PAUSE_SUBSCRIPTION_SCREEN_SHOW,
      eventProperty,
    )
  }

  logPauseSubscriptionScreenClose(userAnswer: string, userId: string): void {
    const eventProperty = {
      answer: userAnswer,
      user_ID: userId,
    }

    this.logEventOrPushToQueue(
      Events.PAUSE_SUBSCRIPTION_SCREEN_CLOSE,
      eventProperty,
    )
  }

  logDiscountOfferScreenShow(question: string, userId: string): void {
    const eventProperty = {
      question,
      user_ID: userId,
    }
    this.logEventOrPushToQueue(Events.DISCOUNT_OFFER_SCREEN_SHOW, eventProperty)
  }

  logDiscountOfferScreenClose(userAnswer: string, userId: string): void {
    const eventProperty = {
      answer: userAnswer,
      user_ID: userId,
    }
    this.logEventOrPushToQueue(
      Events.DISCOUNT_OFFER_SCREEN_CLOSE,
      eventProperty,
    )
  }

  logPauseSubscriptionButtonTap(source: string, userId: string): void {
    const eventProperty = {
      source,
      user_ID: userId,
    }
    this.logEventOrPushToQueue(
      Events.PAUSE_SUBSCRIPTION_BUTTON_TAP,
      eventProperty,
    )
  }

  logTurnOffRenewalButtonTap({
    source,
    subscriptionType,
    userId,
  }: {
    source: string
    subscriptionType: SubscriptionType
    userId: string
  }): void {
    const eventProperty = {
      source,
      subscription_type: subscriptionType,
      user_ID: userId,
    }
    this.logEventOrPushToQueue(
      Events.TURN_OFF_AUTO_RENEWAL_BUTTON_TAP,
      eventProperty,
    )
  }

  logSubscriptionPause({
    productId,
    pauseDate,
    paymentMethod,
    source,
    email,
    userId,
  }: {
    productId: string
    pauseDate: string
    paymentMethod: string
    source: string
    email: string
    userId: string
  }): void {
    const eventProperty = {
      method: LoginMethod.EMAIL,
      product_id: productId,
      pause_date: pauseDate,
      payment_method: paymentMethod,
      email,
      user_ID: userId,
      source,
    }
    this.logEventOrPushToQueue(Events.SUBSCRIPTION_PAUSED, eventProperty)
  }

  logSubscriptionPauseFailed({
    productId,
    pauseDate,
    paymentMethod,
    source,
    email,
    userId,
    error,
  }: {
    productId: string
    pauseDate: string
    paymentMethod: string
    source: string
    email: string
    userId: string
    error: string
  }): void {
    const eventProperty = {
      method: LoginMethod.EMAIL,
      product_id: productId,
      pause_date: pauseDate,
      payment_method: paymentMethod,
      email,
      user_ID: userId,
      source,
      error,
    }
    this.logEventOrPushToQueue(Events.SUBSCRIPTION_PAUSE_FAILED, eventProperty)
  }

  logSubscriptionUpdated({
    productId,
    paymentMethod,
    source,
    email,
    userId,
    price,
    newPrice,
    discount,
  }: {
    productId: string
    paymentMethod: string
    source: string
    email: string
    userId: string
    price: number
    newPrice: number
    discount: number
  }): void {
    const eventProperty = {
      method: LoginMethod.EMAIL,
      product_id: productId,
      payment_method: paymentMethod,
      email,
      user_ID: userId,
      source,
      price,
      new_price: newPrice,
      discount,
    }
    this.logEventOrPushToQueue(Events.SUBSCRIPTION_UPDATED, eventProperty)
  }

  logSubscriptionUpdateFailed({
    productId,
    paymentMethod,
    source,
    email,
    userId,
    price,
    newPrice,
    discount,
    error,
  }: {
    productId: string
    paymentMethod: string
    source: string
    email: string
    userId: string
    price: number
    newPrice: number
    discount: number
    error: string
  }): void {
    const eventProperty = {
      method: LoginMethod.EMAIL,
      product_id: productId,
      payment_method: paymentMethod,
      email,
      user_ID: userId,
      source,
      price,
      new_price: newPrice,
      discount,
      error,
    }
    this.logEventOrPushToQueue(Events.SUBSCRIPTION_UPDATE_FAILED, eventProperty)
  }

  logExtendSubscriptionOfferShown(
    extensionPeriod: number,
    userId: string,
  ): void {
    const event = Events.EXTENSION_SUBSCRIPTION_OFFER_SHOW
    const eventProperty = {
      extension_period: `${extensionPeriod}_months`,
      user_ID: userId,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logExtendSubscriptionOfferAccepted(
    extensionPeriod: number,
    userId: string,
  ): void {
    const event = Events.EXTENSION_SUBSCRIPTION_OFFER_ACCEPTED
    const eventProperty = {
      extension_period: `${extensionPeriod}_months`,
      user_ID: userId,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logExtendSubscriptionOfferSuccess(
    extensionPeriod: number,
    userId: string,
  ): void {
    const event = Events.EXTENSION_SUBSCRIPTION_OFFER_SUCCESS
    const eventProperty = {
      extension_period: `${extensionPeriod}_months`,
      user_ID: userId,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logExtendSubscriptionOfferFailed({
    extensionPeriod,
    userId,
    error,
  }: {
    extensionPeriod: number
    userId: string
    error?: string
  }): void {
    const event = Events.EXTENSION_SUBSCRIPTION_OFFER_FAILED
    const eventProperty = {
      extension_period: `${extensionPeriod}_months`,
      user_ID: userId,
      ...(error && { error }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logTermsOfUseClicked(source: string, userId?: string): void {
    const event = Events.TERMS_OF_USE
    const eventProperty = {
      source,
      ...(userId && { user_ID: userId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logOpenApp(userId: string): void {
    const event = Events.OPEN_APP
    const eventProperty = { user_ID: userId }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPrivacyPolicyClicked(source: string, userId?: string): void {
    const event = Events.PRIVACY_POLICY
    const eventProperty = {
      source,
      ...(userId && { user_ID: userId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logContactSupportClicked(source: string, userId?: string): void {
    const event = Events.CONTACT_SUPPORT
    const eventProperty = {
      source,
      ...(userId && { user_ID: userId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logHelpCenterClicked(source: string, userId?: string): void {
    const event = Events.HELP_CENTER
    const eventProperty = {
      source: EVENT_SOURCE.ACCOUNT_PROFILE,
      ...(userId && { user_ID: userId }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logResetLinkClicked(source: string): void {
    const event = Events.RESET_LINK
    const eventProperty = {
      source,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logSupportVidgetClicked(source: string): void {
    const event = Events.SUPPORT_VIDGET_TAP
    const eventProperty = {
      source,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logResetPageClosed(source: string): void {
    const event = Events.RESET_PAGE_CLOSED
    const eventProperty = {
      source,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logForgotPassword(source: string): void {
    const event = Events.FORGOT_PASSWORD
    const eventProperty = {
      source,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logQuestion({
    event,
    question,
    answers,
    userId,
    customReason,
  }: {
    event: Events
    question: string
    answers: TAnswer
    userId: string
    customReason?: string
  }): void {
    const eventProperty = {
      question,
      answer: Array.isArray(answers) ? answers.join(',') : answers,
      user_ID: userId,
      ...(customReason && { custom_reason: customReason }),
    }

    this.logEventOrPushToQueue(event, eventProperty)
  }

  logGrowthBookAbSegmentName({
    variantId,
    experimentKey,
    variantName,
  }: {
    experimentKey: string
    variantId: string
    variantName: string
  }): void {
    const event = Events.AB_SEGMENT
    const eventProperty = {
      growthbook_feature_key: experimentKey || 'experiment_id_not_set',
      ab_variant: variantId,
      ab_segment_name: variantName || 'no_name',
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logInstructionPopupOpened(userId: string, type: string): void {
    const eventProperty = {
      type,
      ...(userId && { user_ID: userId }),
    }
    const event = Events.INSTRUCTIONS_POPUP_OPEN

    this.logEventOrPushToQueue(event, eventProperty)
  }

  logInstructionPopupClosed(userId: string, tap: string): void {
    const eventProperty = {
      tap,
      ...(userId && { user_ID: userId }),
    }
    const event = Events.INSTRUCTIONS_POPUP_CLOSE

    this.logEventOrPushToQueue(event, eventProperty)
  }

  private logEventOrPushToQueue(
    event: Events,
    eventProperty?: Record<string, any>,
  ): void {
    if (this.getIsGiaActive) {
      this.logEvent({
        event,
        eventProperty,
      })
    } else {
      this.eventsQueue.push({
        event,
        eventProperty,
      })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent({
          event,
          eventProperty,
        }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({
    event,
    eventProperty,
  }: {
    event: Events
    eventProperty?: Record<string, any>
  }): void {
    this.loggers?.forEach((logger) => {
      logger.log(event, eventProperty)
    })
  }
}

export const eventLogger = new EventLoggerService()
