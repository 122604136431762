import { AppName, Color, TRetentionFlowApps } from 'root-constants'

export type TTextareaColorStylesProps = {
  bg: string
  border: string
}

export const DEFAULT_TEXTAREA_COLORS: TTextareaColorStylesProps = {
  bg: Color.GRAY_20,
  border: Color.GRAY_30,
}

export const APP_TEXTAREA_COLORS_MAP: Record<
  TRetentionFlowApps,
  TTextareaColorStylesProps
> = {
  [AppName.LUVLY]: {
    bg: Color.GRAY_20,
    border: Color.GRAY_30,
  },
  [AppName.DANCEBIT]: {
    bg: Color.GRAY_20,
    border: Color.GRAY_30,
  },
  [AppName.NUTRIMATE]: {
    bg: Color.WHITE,
    border: '#cacaca',
  },
  [AppName.FITME]: {
    bg: Color.GRAY_20,
    border: Color.GRAY_30,
  },
  [AppName.WOOFZ]: {
    bg: Color.WHITE,
    border: Color.GRAY_30,
  },
}
