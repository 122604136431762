import { TAnswers } from 'models/common.model'
import {
  IUserInAppPurchase,
  IUserSubscriptionsInfo,
  TCustomerAvailableFeatures,
} from 'models/commonApi.model'
import { IAppState } from 'models/store.model'

import { PaymentSystem } from 'root-constants'

export const selectAuthToken = (state: IAppState): string =>
  state.user.authToken

export const selectUserSubscriptionInfo = (
  state: IAppState,
): IUserSubscriptionsInfo | null => state.user.userSubscriptionsInfo

export const selectUserSecondariesSubscriptionInfo = (
  state: IAppState,
): IUserSubscriptionsInfo[] => state.user.userSecondariesSubscriptionsInfo

export const selectUserInApps = (state: IAppState): IUserInAppPurchase[] =>
  state.user.userInApps || []

export const selectUserContactEmail = (state: IAppState): string =>
  state.user.userContactEmail

export const selectUserName = (state: IAppState): string => state.user.userName

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectAutologinToken = (state: IAppState): string =>
  state.user.autologinToken
export const selectAnswers = (state: IAppState): Partial<TAnswers> | null =>
  state.user.answers

export const selectCustomerAvailableFeatures = (
  state: IAppState,
): TCustomerAvailableFeatures => state.user.availableFeatures

export const selectPaymentMethod = (state: IAppState): string =>
  state.user.paymentMethod || ''

export const selectPaymentSystem = (
  state: IAppState,
): PaymentSystem | undefined => state.user.paymentSystem

export const selectUserCountry = (state: IAppState): string =>
  state.user.country

export const selectIsUpgradePurchased = (state: IAppState): boolean =>
  state.user.isUpgradePurchased

export const selectUserCohort = (state: IAppState): string =>
  state.user.cohortName || ''
