import { AppName, TRetentionFlowApps, UnsubscribeReason } from 'root-constants'

export const REASONS_OPTION_VALUES: Record<
  TRetentionFlowApps,
  UnsubscribeReason[]
> = {
  [AppName.LUVLY]: [
    UnsubscribeReason.DO_NOT_LIKE_PROGRAM,
    UnsubscribeReason.TOO_EXPENSIVE,
    UnsubscribeReason.DIFFICULTIES,
    UnsubscribeReason.NO_RESULTS,
    UnsubscribeReason.DID_NOT_GET_WHAT_EXPECTED,
    UnsubscribeReason.NO_UPDATES,
  ],
  [AppName.DANCEBIT]: [
    UnsubscribeReason.TIMING_IS_NOT_RIGHT,
    UnsubscribeReason.TOO_EXPENSIVE,
    UnsubscribeReason.DIFFICULTIES,
    UnsubscribeReason.NO_RESULTS,
    UnsubscribeReason.DID_NOT_GET_WHAT_EXPECTED,
    UnsubscribeReason.HEALTH_PROBLEMS,
  ],
  [AppName.NUTRIMATE]: [
    UnsubscribeReason.DID_NOT_GET_WEIGHT_GOAL,
    UnsubscribeReason.TOO_EXPENSIVE,
    UnsubscribeReason.DIFFICULTIES,
    UnsubscribeReason.NO_RESULTS,
    UnsubscribeReason.NO_UPDATES,
    UnsubscribeReason.DO_NOT_UNDERSTAND_PROGRAM,
  ],
  [AppName.FITME]: [
    UnsubscribeReason.DO_NOT_LIKE_PROGRAM,
    UnsubscribeReason.TOO_EXPENSIVE,
    UnsubscribeReason.DIFFICULTIES,
    UnsubscribeReason.NO_RESULTS,
    UnsubscribeReason.DID_NOT_GET_WHAT_EXPECTED,
    UnsubscribeReason.NO_UPDATES,
  ],
  [AppName.WOOFZ]: [
    UnsubscribeReason.DO_NOT_LIKE_PROGRAM,
    UnsubscribeReason.TOO_EXPENSIVE,
    UnsubscribeReason.DIFFICULTIES,
    UnsubscribeReason.NO_RESULTS,
    UnsubscribeReason.DID_NOT_GET_WHAT_EXPECTED,
    UnsubscribeReason.NO_UPDATES,
  ],
}
