import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getAuth, signOut } from 'firebase/auth'

import {
  cancelExtraAction,
  getUserSubscriptionsInfoAction,
} from 'root-redux/actions/user'
import { selectSource } from 'root-redux/selects/common'
import { selectUUID, selectUserSubscriptionInfo } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { getSubscriptionUIStatus } from 'helpers/getSubscriptionUIStatus'

import { getBillingCycleKey } from 'modules/unsubscribe/helpers'

import { IUserExtraSubscription } from 'models/commonApi.model'

import { eventLogger } from 'services/eventLogger.service'

import { SubscriptionContainer as Container } from 'common-styles'
import {
  MONTHS_IN_YEAR,
  SubscriptionInterval,
  SubscriptionType,
} from 'root-constants'

import { StyledSubscriptions as S } from './Subscriptions.styles'
import {
  EXTRA_PRODUCT_NAMES_MAP,
  EXTRA_SUBSCRIPTION_DETAILS_MAP,
  STATUSES_TEXT_MAP,
} from './constants'

type TExtraSubscriptionProps = {
  extra: IUserExtraSubscription
}

export const ExtraSubscription: React.FC<TExtraSubscriptionProps> = ({
  extra,
}: TExtraSubscriptionProps) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const source = useSelector(selectSource)
  const userId = useSelector(selectUUID)

  const {
    isActiveSubscription,
    isCanceledSubscription,
    isTrialSubscription,
    isPausedSubscription,
  } = getSubscriptionUIStatus(extra.status)

  const isMonthlyOrYearlyBillingCycle =
    userSubscriptionInfo?.interval === SubscriptionInterval.MONTH ||
    userSubscriptionInfo?.interval === SubscriptionInterval.YEAR

  const monthsQty =
    userSubscriptionInfo?.interval === SubscriptionInterval.YEAR
      ? userSubscriptionInfo.intervalCount * MONTHS_IN_YEAR
      : userSubscriptionInfo?.intervalCount

  const signOutFirebase = useCallback(() => signOut(getAuth()), [])

  const removeSubscription = async () => {
    eventLogger.logTurnOffRenewalButtonTap({
      source,
      subscriptionType: SubscriptionType.UPSELL,
      userId,
    })

    await dispatch(cancelExtraAction(extra.productId))

    dispatch(getUserSubscriptionsInfoAction(signOutFirebase))
  }

  const getExtraProgramTitle = () => {
    if (EXTRA_PRODUCT_NAMES_MAP[extra.productId]) {
      return `${t('subscription.extraProgram.title')} “${t(
        EXTRA_PRODUCT_NAMES_MAP[extra.productId],
      )}”`
    }

    return t('subscription.extraProgram.title')
  }

  if (!userSubscriptionInfo) {
    return null
  }

  const SubscriptionDetails = EXTRA_SUBSCRIPTION_DETAILS_MAP[extra.status]

  return (
    <Container.Root isBottomSeparatorHidden={!isActiveSubscription}>
      <Container.CardTitle>{getExtraProgramTitle()}</Container.CardTitle>

      <S.SubscriptionRow>
        <span>
          <Trans
            i18nKey="subscription.extraProgram.status"
            components={[<br />]}
          />
        </span>
        <S.Status status={extra.status}>
          <Trans
            i18nKey={STATUSES_TEXT_MAP[extra.status]}
            components={[<br />]}
          />
        </S.Status>
      </S.SubscriptionRow>

      {SubscriptionDetails && <SubscriptionDetails extra={extra} />}

      {(isTrialSubscription || isPausedSubscription) && <S.Separator />}

      {!isCanceledSubscription && (
        <>
          <S.SubscriptionRow>
            <span>{t('subscription.billingPeriod')}</span>
            <Container.InfoValue>
              {isMonthlyOrYearlyBillingCycle
                ? t('subscription.billingPeriods.month', {
                    count: monthsQty,
                  })
                : t(
                    `subscription.trialBillingPeriods.${getBillingCycleKey(
                      userSubscriptionInfo.intervalCount,
                    )}`,
                    {
                      count: userSubscriptionInfo.intervalCount,
                    },
                  )}
            </Container.InfoValue>
          </S.SubscriptionRow>
          <S.SubscriptionRow>
            <span>{t('subscription.nextChargeDate')}</span>
            <Container.InfoValue>
              {userSubscriptionInfo.nextPayment}
            </Container.InfoValue>
          </S.SubscriptionRow>
          <S.SubscriptionRow withoutBottomOffset={!isActiveSubscription}>
            <span>{t('subscription.extraProgram.price')}</span>
            <Container.InfoValue>
              <Trans
                i18nKey="subscription.priceAmount"
                values={{
                  currency: userSubscriptionInfo.currencySymbol,
                  amount: extra.amount,
                }}
              />
            </Container.InfoValue>
          </S.SubscriptionRow>
        </>
      )}

      {isActiveSubscription && (
        <Container.ResetPassword onClick={removeSubscription}>
          {t('subscription.turnOffRenewal')}
        </Container.ResetPassword>
      )}
    </Container.Root>
  )
}
